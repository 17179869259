import React from "react";

type Props = {
  percentage: number;
  text: string;
};

const Precentage = ({ percentage, text }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "10px",
        width: "fit-content",
        padding: "0.45rem",
      }}
    >
      <span style={{ color: "#48BB78", fontWeight: "bold", marginRight: "4px" }}>
        {percentage}%
      </span>
      <span style={{ color: "#718096", fontSize: "14px" }}>{text}</span>
    </div>
  );
};

export default Precentage;
