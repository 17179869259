import { Tag } from "antd";

const VVPM = () => {
  return (
    <Tag
      color="#FEEBC8"
      style={{ color: "#C05621", border: "none", fontWeight: "bold", padding: "4px 8px" }}
    >
      VVPM
    </Tag>
  );
};

export default VVPM;
