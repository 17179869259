import { ConversationStatus, FooterButtonsText, UserType } from "./constants";
import { ConversationStep } from "./types";

export const conversationSteps: ConversationStep[] = [
  {
    step: 1,
    type: UserType.ADMIN, //"admin",
    timestamp: "2:04 PM, 15 Nov",
    titleMessage: "Let's start by defining the following asset parameters:",
    componentName: "AssetParametersSelection",
    footerButtonText: [FooterButtonsText.NEXT], //["next"]
    status: ConversationStatus.DONE, // "done", "process"
  },
  {
    step: 2,
    type: UserType.ADMIN, //"admin",
    timestamp: "2:04 PM, 15 Nov",
    titleMessage:
      "Share a reference artefact that you would want to replicate, I have integration with Veeva Vault PromoMats. Do you want to pull assets or upload your assets?",
    componentName: "ArtefactsSourceSelection",
    footerButtonText: [FooterButtonsText.NEXT], //["next"]
    status: ConversationStatus.DONE, // "done", "process"
  },

  {
    step: 3,
    type: UserType.USER, //"user",
    timestamp: "2:05 PM, 15 Nov",
    titleMessage: "Search From VVPM",
    status: ConversationStatus.PROCESS, // "done", "process"
  },

  {
    step: 4,
    type: UserType.ADMIN, //"admin",
    timestamp: "2:04 PM, 15 Nov",
    titleMessage:
      "Based on the above details we have found some existing artefacts that in the Veeva Vault PromoMats Select one of the assets for replication.",
    componentName: "ArtefactsListing",
    footerButtonText: [FooterButtonsText.UPLOAD_INSTEAD, FooterButtonsText.NEXT], //["next"]
    status: ConversationStatus.WAIT, // "done", "process"
  },
];

// const conversationStepsStatus = [
//   {
//     step: 1,
//     status: "done"
//   }
// ];

export const conversationData = [
  {
    step: 1,
    apiResponses: {
      parameters: {
        channel: [
          { name: "Banners", id: "banners" },
          { name: "Email", id: "email" },
        ],
      },
      brand: [{ name: "Intel", id: "intel" }],
    },
    userSelections: {
      parameters: { channel: "banners", brand: "intel" }, //Ids
    },
  },
];

export const sourcePDFURLs = {
  PainCure:
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/PainCure_Global_English_1.pdf",
  Stubixyn:
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_Source_US_English.pdf",
  PREVIVA:
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/Previva+RTE+Source+US+Eng.pdf",
};
