import React, { LegacyRef, useEffect, useRef } from "react";
import "./UserMessage.css";
import { ConversationStep } from "../../../resources/types";
import { ASSETS } from "../../../../../assets/assetsSrcMap";

interface MessageProps {
  chatData: ConversationStep;
}

const UserMessage: React.FC<MessageProps> = ({ chatData }) => {
  const currentElRef = useRef<HTMLDivElement>();
  const { timestamp, titleMessage } = chatData;

  useEffect(() => {
    if (currentElRef.current) {
      setTimeout(() => {
        currentElRef.current &&
          currentElRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
    }
  }, [currentElRef.current]);

  return (
    <div className="user-message-container" ref={currentElRef as LegacyRef<HTMLDivElement>}>
      <div className="message-container">
        <div className="timestamp">{timestamp}</div>
        <div className="message">{titleMessage}</div>
      </div>
      <div className="message-icons">
        <img src={ASSETS.CR_EDIT_MESSAGE_ICON} alt="Edit" className="edit-icon icon" />
        <img src={ASSETS.CR_PIN_MESSAGE_ICON} alt="Pin" className="pin-icon icon" />
        <img src={ASSETS.CR_USER_AVATAR_ICON} alt="Avatar" className="avatar" />
      </div>
    </div>
  );
};

export default UserMessage;
