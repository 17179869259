import React, { useEffect } from "react";
import { Upload, message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import Frame1 from "../assets/Frame1.png";
import { useAppDispatch } from "../../store/hooks";
import { setCurrentStepLoading } from "../../store/slices/contentReplicatorSlice";

const containerStyle: React.CSSProperties = {
  width: "100%",
  maxWidth: "800px",
  // margin: "0 auto",
  padding: "20px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  backgroundColor: "#ffffff",
};

const rowStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
};

const titleStyle: React.CSSProperties = {
  fontSize: "14px",
  marginBottom: "16px",
  textAlign: "left",
};

const logoStyle: React.CSSProperties = {
  width: "150px",
  height: "auto",
  marginBottom: "10px",
};
export default function VeevaPromoMats() {
  const [isSelected, setIsSelected] = React.useState(false);

  const handleCardClick = () => {
    setIsSelected(!isSelected);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentStepLoading(true));
  }, []);

  useEffect(() => {
    dispatch(setCurrentStepLoading(!isSelected));
  }, [isSelected]);

  const cardStyle: React.CSSProperties = {
    flex: 1,
    padding: "20px",
    border: `1px solid ${isSelected ? "blue" : "#e0e0e0"}`,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "169.99px",
    cursor: "pointer",
  };

  const uploadProps: UploadProps = {
    disabled: true,
    name: "file",
    multiple: false,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div style={containerStyle}>
      <div style={rowStyle}>
        {/* Left side */}
        <div style={{ flex: 1 }}>
          <div style={titleStyle}>Pull From VVPM</div>
          <div style={cardStyle} onClick={handleCardClick}>
            <img
              src={Frame1}
              alt="Veeva"
              style={{ width: "309px", height: "78px", objectFit: "contain" }}
            />
          </div>
        </div>
        {/* Right side */}
        <div style={{ flex: 1 }}>
          <div style={titleStyle}>Upload Reference File</div>
          <div>
            <Upload.Dragger {...uploadProps} style={{ padding: "1px", minHeight: "150px" }}>
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined style={{ fontSize: "48px", color: "#6366f1" }} />
              </p>
              <p className="ant-upload-text">Drag and drop a file to upload</p>
              <p className="ant-upload-hint">
                or <span style={{ color: "#6366f1", cursor: "pointer" }}>browse</span> to choose a
                file
              </p>
              <p style={{ color: "#6b7280", fontSize: "12px" }}>
                File type: JPEG, PDF, PNG ; Max file size: 100 MB
              </p>
            </Upload.Dragger>
          </div>
        </div>
      </div>
    </div>
  );
}
