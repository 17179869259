export const EndPoints = {
  CR_ASSET_PARAMETERS: "/api/parameters",
  CR_PERSONA: "/api/persona",
  CR_LOCALISED_ASSETS: "/api/localise",
  CR_TRANSLATED_ASSETS: "/api/translate",
  CR_PERSONALISED_ASSETS: "/api/personalise",
};

export const Services = {
  CONTENT_REPLICATOR: "content-replicator",
};

export const BaseURLs: { [key: string]: string } = {
  [Services.CONTENT_REPLICATOR]: process.env.REACT_APP_CONTENT_REPLICATOR_URL || "",
};
