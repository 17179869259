// import "./style.css";
import { useState } from "react";
import React, { useEffect } from "react";
import { Button, Col, Divider, Modal, Row, Skeleton } from "antd";

import { CustomSelect } from "../CustomSelect";
import filterIcon from "../assets/filter_list.svg";
import langageIcon from "../assets/language_chinese_dayi.svg";
import { ClaimIdentificationWithouthTranslation } from "../Slide31";
import { ClaimIdentificationWithouthTranslationDark } from "../Slide31Dark";
import { getAssetElements, getTargetLanguages } from "../../services/apiMethods/commonMethods";
import { getTranslatedElements } from "../../services/apiMethods/translation";

interface Claim {
  id: string;
  name: string;
  key_message: string;
  reference: string[];
  footnote: string[];
  language: string;
}

export const ClaimIdentificationLocalisationWithTranslation: React.FC<{
  open: boolean;
  setOpen: Function;
  headerAddOn?: string;
}> = ({ open, setOpen, headerAddOn }) => {
  //const [visible, setVisible] = useState(true);
  const handleCancel = () => setOpen(prev => !prev);
  const handleOk = () => setOpen(prev => !prev);

  const [claims, setClaims] = useState<Claim[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [targetLanguage, setTargetLanguage] = useState([]);
  const [originalClaims, setOriginalClaims] = useState([]);
  const [selectedItemIndices, setSelectedItemIndices] = useState<number[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string>("All");
  const [orignalElementFiltered, setOrignalElementFiltered] = useState([]);

  useEffect(() => {
    if (selectedTag !== "All") {
      const filteredData = originalClaims.filter(d => d.key_message === selectedTag);
      //@ts-ignore
      setOrignalElementFiltered(filteredData);
    } else {
      setOrignalElementFiltered(originalClaims);
    }
  }, [selectedTag]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const res = await getTranslatedElements();
      if (res.status === 200) {
        setIsLoading(false);
        setClaims(res.data.data["claims"]);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const res = await getTargetLanguages();
      if (res.status === 200) {
        setTargetLanguage(Object.values(res.data.data));
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const res = await getAssetElements();
      if (res.status === 200) {
        setOriginalClaims(res.data.data["claims"]);
        setOrignalElementFiltered(res.data.data["claims"]);
        const tagsSet = new Set();
        res?.data?.data?.claims?.forEach(d => {
          tagsSet.add(d.key_message);
        });
        const tags = Array.from(tagsSet);
        console.log("tags:", tags);
        setTags(tags as string[]);
      }
    })();
  }, []);
  function mergeArrays(localised: any, original: any) {
    const len = original?.length;
    const newArr = [];
    for (let i = 0; i < len; i++) {
      const obj = {};
      obj["original"] = original[i];
      obj["localised"] = localised[i];
      newArr.push(obj);
    }
    return newArr;
  }

  const newArr = mergeArrays(claims, orignalElementFiltered);
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setSelectedTag(value);
    // You can perform any action here based on the selected value
  };
  return (
    <Modal
      className="fullscreen-modal"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button style={{ marginRight: "3rem" }} key="submit" onClick={handleOk} type="primary">
          Save
        </Button>,
      ]}
      open={open}
      onCancel={handleCancel}
      title={
        <span style={{ fontSize: "large", paddingLeft: ".7rem" }}>
          <b>Content Creation: </b>
          <span style={{ fontWeight: "lighter" }}>
            {headerAddOn || "Equivalent Claim Identification with Translation"}
          </span>
        </span>
      }
    >
      <Row
        gutter={[10, 10]}
        style={{
          borderRadius: ".5rem",
          padding: ".5rem",
          width: "100%",
        }}
      >
        <Row gutter={[16, 16]} style={{ width: "full" }}>
          <Col xs={24} sm={12}>
            <CustomSelect
              w="20rem"
              optionsList={["All"]}
              placeholder="Select"
              icon={<img src={filterIcon} />}
              onChange={handleChange}
              tagsDropDown={tags}
              selectedTag={selectedTag}
            />
          </Col>

          <Col xs={24} sm={12}>
            <CustomSelect
              w="20rem"
              optionsList={targetLanguage}
              placeholder="Select"
              onChange={() => null}
              fieldName="Language"
              icon={<img src={langageIcon} />}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col style={{ flex: "1" }}>
            <b>
              Original Element{" "}
              <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                {/* (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>) */}
              </span>
            </b>
          </Col>
          <Col style={{ flex: "1" }}>
            <b>
              Equivalent Elements{" "}
              <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>)
              </span>
            </b>
          </Col>
        </Row>
        <Skeleton loading={isLoading} paragraph={{ rows: 12 }}>
          <>
            {newArr?.map((item: any, index: number) => {
              return (
                <>
                  <Row gutter={[16, 16]} style={{ width: "100%" }}>
                    <Col style={{ flex: "1" }} key={item.id}>
                      <ClaimIdentificationWithouthTranslation
                        data={item.original}
                        setSelectedItemIndices={setSelectedItemIndices}
                        Index={index}
                        selectedItemIndices={selectedItemIndices}
                      />
                    </Col>

                    <Col style={{ flex: "1" }}>
                      <ClaimIdentificationWithouthTranslationDark
                        data={item.localised}
                        setSelectedItemIndices={setSelectedItemIndices}
                        Index={index}
                        selectedItemIndices={selectedItemIndices}
                      />
                    </Col>
                  </Row>
                  <Divider />
                </>
              );
            })}
          </>
        </Skeleton>
      </Row>
    </Modal>
  );
};
