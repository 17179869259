export const errorMessages: Record<number | string, { message: string; description: string }> = {
  401: {
    message: "Unauthorized",
    description: "Your session has expired. Please log in again.",
  },
  403: {
    message: "Forbidden",
    description: "You do not have permission to access this resource.",
  },
  404: {
    message: "Not Found",
    description: "The requested resource could not be found.",
  },
  500: {
    message: "Server Error",
    description: "An internal server error occurred. Please try again later.",
  },
  default: {
    message: "Error",
    description: "An unexpected error occurred.",
  },
};
