import { ASSETS } from "../../../assets/assetsSrcMap";

export const CRModuleHeader = () => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          fontSize: "24px",
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <img src={ASSETS.CONTENT_REPLICATOR_MODULE_ICON} height={30} width={30} />
        Derivative Content Creation
      </div>
      <div style={{ color: "#00A739", fontSize: "12px", display: "flex", alignItems: "center" }}>
        ID: 00795
      </div>
    </div>
  );
};
