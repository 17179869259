import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Image, Col, Input, Skeleton, Tooltip } from "antd";
import {
  LikeOutlined,
  DislikeOutlined,
  ReloadOutlined,
  SearchOutlined,
  ExpandAltOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { getAssets } from "../../services/apiMethods/assets";
import { AssetViewer } from "../../pages/Content Replicator/components/common/AssetViewer/AssetViewer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setCurrentStepLoading,
  setSelectedArtefactId,
} from "../../store/slices/contentReplicatorSlice";

interface Asset {
  asset_id: string;
  thumbnail: string;
  url: string;
  asset_name: string;
}

export const Slide8 = () => {
  const { selectedAssetParameters } = useAppSelector(state => state.contentReplicator);
  const [isLoading, setIsLoading] = useState(false);
  const [assetList, setAssetList] = useState<Asset[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [clickedAssetId, setClickedAssetId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset>();

  const currentElRef = useRef<HTMLDivElement>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentStepLoading(true));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const res = await getAssets(selectedAssetParameters["brand"]);
      if (res.status === 200) {
        setAssetList(res.data.data);
        setTimeout(() => setIsLoading(false), 2500);
        // setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    selectedAsset && dispatch(setSelectedArtefactId(selectedAsset.asset_id));
  }, [selectedAsset]);

  useEffect(() => {
    if (currentElRef.current) {
      setTimeout(() => {
        currentElRef.current &&
          currentElRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
    }
  }, [currentElRef.current, isLoading]);

  useEffect(() => {
    if (selectedAsset) dispatch(setCurrentStepLoading(false));
  }, [selectedAsset]);

  const getUpdatedAssetList = () =>
    assetList?.filter(({ asset_id }) => asset_id.includes(searchQuery));

  const updatedAssetList = searchQuery ? getUpdatedAssetList() : assetList;

  return (
    <Card style={{ width: "100%", maxWidth: 700 }} ref={currentElRef}>
      <Row style={{ paddingBottom: "1rem" }}>
        <Input
          addonBefore={<SearchOutlined />}
          placeholder="Search by Asset ID or Keywords"
          onChange={e => setSearchQuery(e.target.value)}
        />
      </Row>
      <Skeleton loading={isLoading} paragraph={{ rows: 5 }} active>
        <Row gutter={[16, 16]}>
          {updatedAssetList.map(item => (
            <Col xs={24} sm={12} md={8} key={item.asset_id} style={{ position: "relative" }}>
              <p style={{ margin: "0", textAlign: "left" }}>Asset ID: {item.asset_id}</p>
              <div
                style={{
                  position: "relative",
                  border:
                    selectedAsset?.asset_id === item.asset_id
                      ? "2px solid #5d2cc9"
                      : "2px solid transparent",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <Image
                  src={
                    item.thumbnail ||
                    "https://cdn.pixabay.com/photo/2017/09/16/15/49/sea-2755858_1280.jpg"
                  }
                  width="100%"
                  style={{
                    minHeight: "5rem",
                    // opacity: clickedAssetId === item.asset_id ? ".7" : "1",
                    transition: "opacity 0.3s ease",
                    // border: clickedAssetId === item.asset_id ? "3px solid skyBlue" : "",
                  }}
                  preview={false}
                  onClick={() => {
                    setClickedAssetId(prev => (prev === item.asset_id ? "" : item.asset_id));
                    setSelectedAsset(item);
                  }}
                />
                <Tooltip title="Expand">
                  <ExpandAltOutlined
                    style={{
                      position: "absolute",
                      zIndex: 100,
                      top: 10,
                      right: 10,
                      fontSize: "18px",
                      color: "#fff",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      padding: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setClickedAssetId(prev => (prev === item.asset_id ? "" : item.asset_id));
                      setSelectedAsset(item);
                      setOpenModal(true);
                    }}
                  />
                </Tooltip>
                {selectedAsset?.asset_id === item.asset_id && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      border: "2px solid #6752e9",
                      borderRadius: 4,
                      boxSizing: "border-box",
                    }}
                  >
                    <CheckCircleFilled
                      style={{
                        position: "absolute",
                        bottom: 4,
                        left: 4,
                        color: "#6752e9",
                        fontSize: 24,
                      }}
                    />
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </Skeleton>
      <Row justify="start" gutter={[16, 0]} style={{ paddingTop: ".5rem" }}>
        <Col>
          <Button type="text" disabled icon={<LikeOutlined />} className="no-border-button" />
        </Col>
        <Col>
          <Button type="text" disabled icon={<DislikeOutlined />} className="no-border-button" />
        </Col>
        <Col>
          <Button type="text" disabled icon={<ReloadOutlined />} className="no-border-button" />
        </Col>
      </Row>

      {/* <Slide11 setOpenModal={setOpenModal} openModal={openModal} item={selectedAsset} /> */}
      <AssetViewer
        open={openModal}
        setOpen={setOpenModal}
        item={selectedAsset}
        assetsList={assetList}
      />
    </Card>
  );
};
