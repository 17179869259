import { CheckCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";
const { Text } = Typography;

export const MLRApproved = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "16px",
      }}
    >
      <div style={{ marginRight: "10px" }}>
        <CheckCircleFilled style={{ color: "green", fontSize: "16px" }} />
      </div>
      <Text style={{ fontWeight: "normal", fontSize: "12px" }}>
        Assets Submitted for MLR review
      </Text>
    </div>
  );
};
