import { Radio, Tag } from "antd";
import React, { useState } from "react";
export const CustomPanelHeader: React.FC<{
  id: string | React.ReactElement;
  tag: string;
  title: string;
  data: any;
  isSuggestionsAvailable?: boolean;
}> = ({ id, tag, title, data, isSuggestionsAvailable = false }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ display: "flex", alignContent: "center" }}>
          <span onClick={e => e.stopPropagation()}>
            <Radio value={data?.id} defaultChecked={!isSuggestionsAvailable} />
          </span>
          <span>
            PromoMats ID: <span style={{ color: "blue" }}>{id}</span>
          </span>
        </span>
        <span>
          <Tag>{tag}</Tag>
        </span>
      </div>
      <p style={{ textAlign: "left", paddingLeft: "1.5rem", paddingTop: "1rem" }}>{title}</p>
    </div>
  );
};
