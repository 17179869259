import { Select } from "antd";
import React from "react";

export const CustomSelect: React.FC<{
  fieldName?: string;
  icon?: React.ReactNode;
  optionsList: string[] | [];
  w?: string;
  placeholder: string;
  handleChange?: Function;
  onChange: (val) => void;
  tagsDropDown?: string[];
  selectedTag?: string;
}> = ({
  icon,
  fieldName = "",
  optionsList,
  w = "100%",
  placeholder = "",
  onChange,
  handleChange,
  tagsDropDown,
  selectedTag,
}) => {
  const getInitialValue = () => {
    const val = optionsList?.length === 1 ? optionsList[0] : undefined;
    if (optionsList?.length === 1) {
      return optionsList[0];
    }
    if (fieldName === "Language") {
      return "Spanish";
    }
    return undefined;
  };
  const initialValue = getInitialValue();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #d9d9d9",
        borderRadius: ".5rem",
        whiteSpace: "nowrap",
        width: w,
        flexBasis: "30%",
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "8px",
          paddingLeft: ".5rem",
          whiteSpace: "nowrap",
          minWidth: "fitContent",
        }}
      >
        {icon && <span style={{ marginRight: "4px", paddingTop: ".3rem" }}>{icon}</span>}
        <span style={{ fontWeight: "500" }}>{fieldName ? `${fieldName}:` : ""}</span>
      </span>
      <div style={{ flexGrow: 1 }}>
        <Select
          // allowClear
          bordered={false}
          style={{ width: "100%" }}
          placeholder={placeholder}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
          value={selectedTag ? selectedTag : initialValue}
          onChange={onChange}
        >
          {optionsList?.map(opt => {
            return (
              <>
                <Select.Option value={opt} key={opt} label={opt}>
                  {opt}
                </Select.Option>

                {tagsDropDown?.map(tag => {
                  return (
                    <Select.Option value={tag} label={tag}>
                      {tag}
                    </Select.Option>
                  );
                })}
              </>
            );
          })}
        </Select>
      </div>
    </div>
  );
};
