import React from "react";
import { Collapse } from "antd";
import { CustomPanelHeader } from "./CustomHeader";

interface Data {
  id: string;
  text: string;
  type: string;
}

export function NonClaimSuggestedContent({ data }: { data: Data }) {
  const items = [
    {
      key: "1",
      label: (
        <CustomPanelHeader id={data["id"]} tag={data["type"]} title={data["text"]} data={data} />
      ),
    },
  ];
  return (
    <div
      style={{
        border: "1px solid lightGray",
        borderRadius: "10px",
      }}
    >
      <Collapse
        items={items}
        expandIconPosition="end"
        bordered={false}
        style={{ backgroundColor: "white" }}
      />
    </div>
  );
}
