import { useEffect } from "react";
import Cards from "../../../ss-dev/slide13/Cards";
import VeevaPromoMats from "../../../ss-dev/slide3/VeevaPromoMats";
import {
  ConversationStatus,
  ConversationStepsMapping,
  ConversationStepsName,
  FooterButtonsText,
  LastConversationStep,
  UserType,
} from "../resources/constants";
import AdminMessage from "./Conversation/AdminMessage/AdminMessage";
import UserMessage from "./Conversation/UserMessage/UserMessage";
import { Slide8 } from "../../../ma-dev/Slide8";
import { AssetElementIdentifiedLoaders } from "../steps/Output Processing/AssetElementIdentifiedLoaders";
import { LocalisationWithoutTranslationLoaders } from "../steps/Output Processing/LocalisationWithoutTranslationLoaders";
import { LocalisationWithTranslationLoaders } from "../steps/Output Processing/LocalisationWithTranslationLoaders";
import { PersonalisationWithTranslationLocalisation } from "../steps/Output Processing/PersonalisationWithTranslationLocalisation";
import LocalisationMarketSelect from "../steps/Output Processing/LocalisationMarketSelect";
import { TranslationLanguageSelect } from "../steps/Output Processing/TranslationLanguageSelect";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setCurrentStepDone,
  setCurrentStepDoneNextStepProcess,
  setCurrentStepperStepFinish,
  setCurrentStepTimestamp,
} from "../../../store/slices/contentReplicatorSlice";
import { PersonalisationWithoutTranslationLocalisation } from "../steps/Output Processing/PersonalisationWithoutTranslationLocalisation";
import { Slide3AssetParameters } from "../../../ma-dev/Slide3";
import { MLRSubmission } from "../steps/MLR/MLRSubmission";
import { MLRApproved } from "../steps/MLR/MLRApproved";

export const CRConversationArea = () => {
  const { currentStep, conversationSteps } = useAppSelector(state => state.contentReplicator);

  const dispatch = useAppDispatch();

  console.log({ currentStep });

  useEffect(() => {
    console.log("useEffect currentstep", currentStep);
    const currentStepData = conversationSteps.find(cs => cs.step === currentStep);
    if (currentStepData?.type === UserType.USER) {
      setTimeout(() => {
        dispatch(setCurrentStepDoneNextStepProcess()); // Also increments the currentStep
      }, 800);
    }
  }, [currentStep]);

  useEffect(() => {
    //First load of the component sets this timestamp
    if (currentStep === 1) {
      dispatch(setCurrentStepTimestamp());
    }
    if (currentStep === LastConversationStep) {
      dispatch(setCurrentStepDone());
      dispatch(setCurrentStepperStepFinish());
      console.log("Last step reached");
    }
  }, [currentStep]);

  const ConversationStepComponents: Record<string, React.ReactNode> = {
    // [ConversationStepsName.ASSET_PARAMETERS_SELECTION]: <AssetParameters />,
    [ConversationStepsName.ASSET_PARAMETERS_SELECTION]: <Slide3AssetParameters />,
    [ConversationStepsName.ARTEFACTS_SOURCE_SELECTION]: <VeevaPromoMats />,
    // [ConversationStepsName.USER_SEARCH_VVPM]: <Cards />,
    [ConversationStepsName.ARTEFACTS_LISTING]: <Slide8 />,
    [ConversationStepsName.LOCALISATION_MARKET_SELECTION]: <LocalisationMarketSelect />,
    [ConversationStepsName.TRANSLATION_LANGUAGE_SELECTION]: <TranslationLanguageSelect />,
    [ConversationStepsName.PERSONA_SELECTION]: <Cards />,
    [ConversationStepsName.PERSONA_SELECTION_ENGLISH]: (
      <Cards text="Transaction Cost for 8 Assets" modalPrice={200} />
    ),
    // [ConversationStepsName.USER_GENERATE_ASSET]: <Cards />,
    [ConversationStepsName.ASSET_ELEMENT_IDENTIFIED]: <AssetElementIdentifiedLoaders />,
    // [ConversationStepsName.USER_PROCEED_TO_LOCALISATION]: <Cards />,
    [ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION]: (
      <LocalisationWithoutTranslationLoaders />
    ),
    // [ConversationStepsName.USER_PROCEED_TO_TRANSLATION]: <Cards />,
    [ConversationStepsName.LOCALISATION_WITH_TRANSLATION]: <LocalisationWithTranslationLoaders />,
    // [ConversationStepsName.USER_PROCEED_TO_PERSONALISATION]: <Cards />,
    [ConversationStepsName.PERSONALISATION_WITHOUT_TRANSLATION_AND_LOCALISATION]: (
      <PersonalisationWithoutTranslationLocalisation />
    ),
    [ConversationStepsName.PERSONALISATION_WITH_TRANSLATION_AND_LOCALISATION]: (
      <PersonalisationWithTranslationLocalisation />
    ),
    [ConversationStepsName.MLR_ASSETS_SUBMISSION]: <MLRSubmission />,
    [ConversationStepsName.MLR_ASSETS_APPROVED]: <MLRApproved />,
  };

  const footerButtonsOnClick = {
    [FooterButtonsText.NEXT]: () => {
      console.log("Next");
      dispatch(setCurrentStepDoneNextStepProcess());
    },
    [FooterButtonsText.UPLOAD_INSTEAD]: () => console.log("Upload Instead"),
    [FooterButtonsText.PROCEED]: () => {
      console.log("Proceed");
      dispatch(setCurrentStepDoneNextStepProcess());
    },
    [FooterButtonsText.SKIP]: () => {
      console.log("Skip");
    },
    [FooterButtonsText.CANCEL]: () => console.log("Cancel"),
    [FooterButtonsText.DEFINE_FOR_ENGLISH]: () => {
      console.log("Define for English");
      dispatch(setCurrentStepDoneNextStepProcess());
    },
    [FooterButtonsText.GENERATE]: () => {
      console.log("Generate");
      dispatch(setCurrentStepDoneNextStepProcess());
    },
    [FooterButtonsText.PUBLISH]: () => {
      console.log("Publish");
      dispatch(setCurrentStepDoneNextStepProcess());
    },
    [FooterButtonsText.SUBMIT_SELECTED_ASSETS]: () => {
      console.log("Submit Selected Assets");
      dispatch(setCurrentStepDoneNextStepProcess());
    },
  };

  return (
    <div style={{ flex: 1, overflow: "auto" }}>
      {conversationSteps.map(chatData => {
        if (chatData.status === ConversationStatus.WAIT) return null;
        if (chatData.type === "admin") {
          return (
            <AdminMessage
              key={chatData.step}
              chatData={chatData}
              footerButtonsOnClick={footerButtonsOnClick}
              children={ConversationStepComponents[ConversationStepsMapping[chatData.step]]}
            />
          );
        } else {
          return <UserMessage key={chatData.step} chatData={chatData} />;
        }
      })}
    </div>
  );
};
