import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from "axios";
import { notification } from "antd";
import { errorMessages } from "../../globals/constants";
import { BaseURLs } from "./apiData";

class ApiService {
  private instance: AxiosInstance;

  constructor(service: string) {
    this.instance = axios.create({
      baseURL: BaseURLs[service] || "",
      timeout: 10000,
    });

    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem("authToken");

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },
      (error: AxiosError) => {
        notification.error({
          message: "Request Error",
          description: "An error occurred before sending the request.",
        });
        return Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response.data;
      },
      (error: AxiosError) => {
        if (error.response) {
          this.handleResponseError(error.response.status);
        } else {
          notification.error({
            message: "Network Error",
            description: "Please check your internet connection and try again.",
          });
        }

        return Promise.reject(error);
      },
    );
  }

  private handleResponseError(status: number) {
    const { message, description } = errorMessages[status] || errorMessages.default;

    notification.error({
      message,
      description,
    });

    if (status === 401) {
      localStorage.removeItem("authToken");
      window.location.href = "/login";
    }
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.get<T>(url, config);
  }

  public async post<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.instance.post<T>(url, data, config);
  }

  public async put<T>(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this.instance.put<T>(url, data, config);
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.instance.delete<T>(url, config);
  }
}

export default ApiService;
