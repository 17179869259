import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";
import Login from "../pages/Login/Login";
import { Home } from "../pages/Home/Home";
import Dashboard from "../pages/Dashboard/Dashboard";
import RouteGuard from "./RouteGuard";
import routes from "./routes";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* <Route path="/login" element={<RouteGuard element={<Login />} />} />
          <Route path="/" element={<RouteGuard element={<Home />} isProtected />} />
          <Route path="/dashboard" element={<RouteGuard element={<Dashboard />} isProtected />} /> */}
          <Route path="*" element={<Navigate to="/" />} />

          {/* TODO: Simplify this */}
          {routes.map(({ path, element: Component, layout: Layout, isProtected }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <RouteGuard
                  element={
                    Layout ? (
                      <Layout>
                        <Component />
                      </Layout>
                    ) : (
                      <Component />
                    )
                  }
                  isProtected={isProtected}
                />
              }
            />
          ))}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

const AppRouter = App;
export default AppRouter;
