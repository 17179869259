import React, { useState } from "react";
import { Modal, Button, Checkbox, Row, Col, Typography } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface TagOption {
  title: string;
  id: number;
  price: number;
  strikethrough: boolean; // Add strikethrough property
}

interface Option {
  label: string;
  description: string;
  tags: TagOption[];
}

interface LocalizationModalProps {
  setPriceModal: (value: boolean) => void;
  priceModal: boolean;
}

const LocalizationModal: React.FC<LocalizationModalProps> = ({ setPriceModal, priceModal }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [options, setOptions] = useState<Option[]>([
    {
      label: "Localisation without Translation",
      description: "This will only localise you assets and skip translation and personalisation",
      tags: [{ title: "Spain in English", id: 0, price: 20, strikethrough: false }],
    },
    {
      label: "Localisation With Translation",
      description: "This will localise and translate your assets without personalisation",
      tags: [
        { title: "Spain in English", id: 1, price: 20, strikethrough: false },
        { title: "Spain in Spanish", id: 2, price: 20, strikethrough: false },
      ],
    },
    {
      label: "Localisation With Personalisation, Without Translation",
      description: "This will localise and personalise your assets without translation",
      tags: [
        { title: "Evidence-Based Physician in English", id: 3, price: 20, strikethrough: false },
        { title: "Tech-Savvy Specialist in English", id: 4, price: 20, strikethrough: false },
        {
          title: "Community-Focused Practitioners in English",
          id: 5,
          price: 20,
          strikethrough: false,
        },
      ],
    },
    {
      label: "Localisation With Translation and Personalisation",
      description: "This will localise, translate and personalise your assets",
      tags: [
        { title: "Evidence-Based Physician in Spanish", id: 6, price: 20, strikethrough: false },
        { title: "Tech-Savvy Specialist in Spanish", id: 7, price: 20, strikethrough: false },
        {
          title: "Community-Focused Practitioners in Spanish",
          id: 8,
          price: 20,
          strikethrough: false,
        },
      ],
    },
  ]);

  const showModal = () => setIsModalVisible(true);
  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => setPriceModal(false);

  const handleTagClick = (optionIndex: number, tagId: number) => {
    setOptions(
      options.map((option, index) =>
        index === optionIndex
          ? {
              ...option,
              tags: option.tags.map(tag =>
                tag.id === tagId ? { ...tag, strikethrough: !tag.strikethrough } : tag,
              ),
            }
          : option,
      ),
    );
  };

  const calculateTotalCost = () =>
    options.reduce(
      (acc, option) =>
        acc + option.tags.reduce((tagAcc, tag) => tagAcc + (tag.strikethrough ? 0 : tag.price), 0),
      0,
    );

  return (
    <>
      <Modal
        title={<Text strong>You can tweak the cost here</Text>}
        visible={priceModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{ style: { backgroundColor: "#6752e9", borderColor: "#6752e9" } }}
        width={800}
        centered
      >
        <Text type="secondary">Add or remove items from the list to see the changes in cost</Text>
        {options.map((option, optionIndex) => (
          <div
            key={optionIndex}
            style={{ marginTop: "20px", paddingBottom: "20px", borderBottom: "1px solid #f0f0f0" }}
          >
            <Checkbox style={{ fontSize: "16px", marginBottom: "8px" }}>{option.label}</Checkbox>
            <Text type="secondary" style={{ display: "block", marginBottom: "12px" }}>
              {option.description}
            </Text>
            {option.tags.map(tag => (
              <Row key={tag.id} align="middle" style={{ marginBottom: "8px" }}>
                <Col span={18}>
                  <Button
                    style={{
                      backgroundColor: "#f3f0ff",
                      border: "none",
                      color: tag.strikethrough ? "gray" : "#333",

                      borderRadius: "16px",
                      padding: "4px 12px",
                      textDecoration: tag.strikethrough ? "line-through" : "none",
                    }}
                  >
                    {tag.title}
                    <Button
                      type="text"
                      icon={tag.strikethrough ? <PlusOutlined /> : <CloseOutlined />}
                      onClick={() => handleTagClick(optionIndex, tag.id)}
                      style={{ marginLeft: "8px", fontSize: "10px" }}
                    />
                  </Button>
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                  <Text
                    style={{
                      color: tag.strikethrough ? "gray" : "#52c41a",
                      textDecoration: tag.strikethrough ? "line-through" : "none",
                    }}
                  >
                    ${tag.price}
                  </Text>
                </Col>
              </Row>
            ))}
          </div>
        ))}
        <Row justify="end" style={{ marginTop: "20px" }}>
          <Col>
            <Text strong>
              Total Transaction Cost for{" "}
              {options.reduce(
                (acc, opt) => acc + opt.tags.filter(tag => !tag.strikethrough).length,
                0,
              )}{" "}
              Assets
            </Text>
            <Text strong style={{ marginLeft: "8px", color: "#52c41a" }}>
              ${calculateTotalCost()}
            </Text>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default LocalizationModal;
