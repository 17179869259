import { LikeOutlined, DislikeOutlined, ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";

const { Link } = Typography;

export const MLRSubmissionFooter: React.FC<{ onDownloadClick: () => void }> = ({
  onDownloadClick,
}) => {
  return (
    <div style={styles.container}>
      <Space size="large">
        <LikeOutlined style={styles.icon} />
        <DislikeOutlined style={styles.icon} />
        <ReloadOutlined style={styles.icon} />
      </Space>

      <Link style={styles.downloadLink} onClick={onDownloadClick}>
        Download All <DownloadOutlined />
      </Link>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #d9d9d9",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  },
  icon: {
    fontSize: "18px",
    cursor: "pointer",
    color: "#000",
  },
  downloadLink: {
    color: "#5d2cc9",
    fontWeight: "500",
    cursor: "pointer",
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
};
