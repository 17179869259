import { Select, Row, Col, Form, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { CONSTANTS } from "../Constants";
import { truncateText } from "../helpers";
import brandIcon from "../assets/pill.svg";
import keyMsgIcon from "../assets/brand_awareness.svg";
import channelIcon from "../assets/captive_portal.svg";
import marketIcon from "../assets/add_location_alt.svg";
import therapeauticIcon from "../assets/stethoscope.svg";
import langIcon from "../assets/language_chinese_dayi.svg";
import { SelectFieldsWrapper } from "./SelectFieldWrapper";
import "./style.css";
import { getParameters } from "../../services/apiMethods/assetParameters";
import { useAppDispatch } from "../../store/hooks";
import {
  setCurrentStepLoading,
  setSelectedAssetParameters,
} from "../../store/slices/contentReplicatorSlice";
const { Option } = Select;
const commonStyling = {
  bordered: false,
  dropdownMatchSelectWidth: false,
};
const { CHANNEL_PLACEHOLDER, CHANNEL_LABEL, BRAND_LABEL, BRAND_PLACEHOLDER } = CONSTANTS;

const initialParamtersData = {
  Channel: ["Banner", "Emailer", "e-Detail", "Social Media Ads"],
  Brand: ["Stubixyn", "Bayer", "Cipla", "Pfizer", "Gilead"],
  "Therapeutic Area": [
    "Cardiology",
    "Dermatology",
    "Hematology",
    "Gastroenterology",
    "Anti-inflammatory",
  ],
  Market: ["US", "Spain", "India", "Japan", "China", "UAE"],
  Language: ["Arabic", "French", "Japanese", "English", "Spanish"],
  "Key Message": ["Safety", "Efficacy", "Inform", "Clinical"],
};
export function Slide3AssetParameters() {
  const [selectedParameters, setSelectedParameters] = useState({
    channel: "",
    brand: "",
    therapeuticArea: "",
    // market: "",
    // language: "",
    // keyMessage: "",
  });

  const [parameters, setParameters] = useState({});
  const channels: string[] = ["Banner", "Emailer", "e-Detail", "Social Media Ads"];

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentStepLoading(true));
  }, []);

  useEffect(() => {
    const allKeysPopulated = Object.keys(selectedParameters).every(
      key => selectedParameters[key] !== "",
    );
    if (allKeysPopulated) dispatch(setCurrentStepLoading(false));
  }, [selectedParameters]);
  const onChangeParams = (paramName, value) => {
    setSelectedParameters({ ...selectedParameters, [paramName]: value });
    dispatch(setSelectedAssetParameters({ ...selectedParameters, [paramName]: value }));
  };

  useEffect(() => {
    (async () => {
      const res = await getParameters();
      if (res.status === 200) {
        setParameters(res.data.data);
      } else {
        setParameters(initialParamtersData);
      }
    })();
  }, []);

  // Utility function for filtering Select options
  const filterSelectOption = (input, option) => {
    return option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Row
      gutter={[6, 6]}
      style={{
        border: "1px solid lightGray",
        padding: ".5rem",
        width: "90%",
        borderRadius: ".5rem",
      }}
    >
      <>
        <Col xs={24} sm={24} md={8}>
          <Form.Item name="channel">
            <SelectFieldsWrapper
              fieldName={CHANNEL_LABEL}
              icon={<img src={channelIcon} style={{ marginTop: "3px", height: "1rem" }} />}
            >
              <Select
                onChange={value => onChangeParams("channel", value)}
                {...commonStyling}
                placeholder={CHANNEL_PLACEHOLDER}
                showSearch
                optionFilterProp="children"
                filterOption={filterSelectOption}
              >
                {channels?.map((name, i) => (
                  <Option title={name} key={i} value={name}>
                    <Tooltip>{truncateText(name, 15)}</Tooltip>
                  </Option>
                ))}
              </Select>
            </SelectFieldsWrapper>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Form.Item name="brand">
            <SelectFieldsWrapper
              fieldName={BRAND_LABEL}
              icon={<img src={brandIcon} style={{ marginTop: "3px", height: "1rem" }} />}
            >
              <Select
                {...commonStyling}
                placeholder={BRAND_PLACEHOLDER}
                onChange={value => onChangeParams("brand", value)}
                showSearch
                optionFilterProp="children"
                filterOption={filterSelectOption}
              >
                {parameters["Brand"]?.map((name, i) => (
                  <Option title={name} key={i} value={name}>
                    <Tooltip>{truncateText(name, 15)}</Tooltip>
                  </Option>
                ))}
              </Select>
            </SelectFieldsWrapper>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Form.Item name="therapeuticArea">
            <SelectFieldsWrapper
              fieldName="Therapeutic Area"
              icon={<img src={therapeauticIcon} style={{ marginTop: "3px", height: "1rem" }} />}
            >
              <Select
                {...commonStyling}
                placeholder="Select & Search"
                onChange={value => onChangeParams("therapeuticArea", value)}
                showSearch
                optionFilterProp="children"
                filterOption={filterSelectOption}
              >
                {parameters["Therapeutic Area"]?.map((name, i) => (
                  <Option title={name} key={i} value={name}>
                    <Tooltip>{truncateText(name, 15)}</Tooltip>
                  </Option>
                ))}
              </Select>
            </SelectFieldsWrapper>
          </Form.Item>
        </Col>

        {/* <Col xs={24} sm={24} md={8}>
          <Form.Item name="market">
            <SelectFieldsWrapper
              fieldName="Market"
              icon={<img src={marketIcon} style={{ marginTop: "3px", height: "1rem" }} />}
            >
              <Select
                {...commonStyling}
                placeholder="Select & Search"
                onChange={value => onChangeParams("market", value)}
                showSearch
                optionFilterProp="children"
                filterOption={filterSelectOption}
              >
                {parameters["Market"]?.map((name, i) => (
                  <Option title={name} key={i} value={name}>
                    <Tooltip>{truncateText(name, 15)}</Tooltip>
                  </Option>
                ))}
              </Select>
            </SelectFieldsWrapper>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Form.Item name="language">
            <SelectFieldsWrapper
              fieldName="Language"
              icon={<img src={langIcon} style={{ marginTop: "3px", height: "1rem" }} />}
            >
              <Select
                {...commonStyling}
                placeholder="Select & Search"
                onChange={value => onChangeParams("language", value)}
                showSearch
                optionFilterProp="children"
                filterOption={filterSelectOption}
              >
                {parameters["Language"]?.map((name, i) => (
                  <Option title={name} key={i} value={name}>
                    <Tooltip>{truncateText(name, 15)}</Tooltip>
                  </Option>
                ))}
              </Select>
            </SelectFieldsWrapper>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Form.Item name="keyMessage">
            <SelectFieldsWrapper
              fieldName="Key Message"
              icon={<img src={keyMsgIcon} style={{ marginTop: "3px", height: "1rem" }} />}
            >
              <Select
                {...commonStyling}
                placeholder="Select & Search"
                onChange={value => onChangeParams("keyMessage", value)}
                showSearch
                optionFilterProp="children"
                filterOption={filterSelectOption}
              >
                {parameters["Key Message"]?.map((name, i) => (
                  <Option title={name} key={i} value={name}>
                    <Tooltip>{truncateText(name, 15)}</Tooltip>
                  </Option>
                ))}
              </Select>
            </SelectFieldsWrapper>
          </Form.Item>
        </Col> */}
      </>
    </Row>
  );
}
