import React from "react";
import { Collapse } from "antd";
import { CustomPanelHeader } from "./CustomHeader";
import reloadIcon from "../assets/autorenew.svg";
import errorOutlinedIcon from "../assets/Error outline.svg";

export function NonClaimIdentificationWithouthTranslation({
  data,
  activeKey = 0,
  Index,
  setSelectedItemIndices,
  selectedItemIndices,
}: any) {
  console.log("test", Index);
  const [expanded, setExpanded] = React.useState(false);
  const items = [
    {
      key: "1",
      label: (
        <CustomPanelHeader
          id={
            data?.id ? (
              <span>
                <span style={{ color: "blue" }}>{data?.id}</span>
              </span>
            ) : (
              <>
                <span style={{ display: "flex", alignItems: "center" }}>
                  PromoMats ID:
                  <img src={errorOutlinedIcon} style={{ marginLeft: "8px" }} />
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                    }}
                  >
                    Claim not found in VVPM
                    <img src={reloadIcon} style={{ marginLeft: "8px" }} />
                  </span>
                </span>
              </>
            )
          }
          tag={data?.type || ""}
          title={data?.text || ""}
        />
      ),
    },
  ];
  return (
    <div
      style={{
        border: "1px solid lightGray",
        borderRadius: "10px",
        height: "100%",
      }}
    >
      <Collapse
        // activeKey={activeKey}
        // onChange={() => setSelectedBox(Index)}
        // {...(setSelectedBox && { onChange: () => setSelectedBox(Index) })}

        // {...(activeKey && { activeKey })}
        onChange={
          setSelectedItemIndices
            ? () =>
                setSelectedItemIndices(prevIndices => {
                  if (prevIndices.includes(Index)) {
                    return prevIndices.filter((item: number) => item !== Index);
                  } else {
                    return [...prevIndices, Index];
                  }
                })
            : () => setExpanded(!expanded)
        }
        activeKey={
          selectedItemIndices ? (selectedItemIndices.includes(Index) ? 1 : 0) : expanded ? 1 : 0
        }
        items={items}
        expandIconPosition="end"
        ghost
      />
    </div>
  );
}
