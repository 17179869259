import React from "react";
import { Col, Collapse, Radio, RadioChangeEvent } from "antd";
import { Children } from "./Children";
import { CustomPanelHeader } from "./CustomHeader";
import { Footer } from "./Footer";
import { useState } from "react";
import { SuggestedContent } from "./SuggestedContent";
import reloadIcon from "../assets/autorenew.svg";
import errorOutlinedIcon from "../assets/Error outline.svg";

interface Data {
  id: string;
  name: string;
  key_message: string;
  reference: string[];
  footnote: string[];
  language: string;
  suggestions?: any;
}

export function ClaimIdentificationWithouthTranslationDark({
  data,
  isSuggestionsAvailable = false,
  Index,
  setSelectedItemIndices,
  selectedItemIndices,
}: {
  data: Data;
  isSuggestionsAvailable?: boolean;
  Index?: number;
  setSelectedItemIndices?: Function;
  selectedItemIndices?: number[];
}) {
  const [expanded, setExpanded] = React.useState(false);

  const [contentOpen, setContentOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(data.id);

  const onChange = (e: RadioChangeEvent) => {
    setSelectedId(e.target.value);
  };
  const items = [
    {
      key: "1",
      label: (
        <CustomPanelHeader
          data={data}
          id={
            data.id ? (
              <span>
                <span style={{ color: "blue" }}>{data.id}</span>
              </span>
            ) : (
              <>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img src={errorOutlinedIcon} style={{ marginLeft: "8px" }} />
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                    }}
                  >
                    Claim not found in VVPM
                    <img src={reloadIcon} style={{ marginLeft: "8px" }} />
                  </span>
                </span>
              </>
            )
          }
          tag={data["key_message"]}
          title={data["name"]}
        />
      ),
      children: <Children references={data["reference"]} footnotes={data["footnote"] || []} />,
    },
  ];
  return (
    <Radio.Group onChange={onChange} value={selectedId} style={{ width: "100%" }}>
      <div
        style={{
          // width: "600px",
          border: "1px solid lightGray",
          borderRadius: "10px",

          height: "100%",
        }}
      >
        <Collapse
          items={items}
          expandIconPosition="end"
          bordered={false}
          onChange={
            setSelectedItemIndices
              ? () =>
                  setSelectedItemIndices(prevIndices => {
                    if (prevIndices.includes(Index)) {
                      return prevIndices.filter((item: number) => item !== Index);
                    } else {
                      return [...prevIndices, Index];
                    }
                  })
              : () => {
                  setExpanded(!expanded);
                }
          }
          activeKey={
            selectedItemIndices ? (selectedItemIndices?.includes(Index) ? 1 : 0) : expanded ? 1 : 0
          }
        />
        <Footer
          onClick={() => setContentOpen(pre => !pre)}
          tag="VVPM"
          rating="98%"
          btnName={
            isSuggestionsAvailable
              ? contentOpen
                ? "Hide Suggestions"
                : "Load More Suggestions"
              : "Load More Suggestions"
          }
        />

        {/* <Col>{contentOpen && <SuggestedContent data={data} />}</Col> */}

        <Col
          style={{
            padding: ".5rem",
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fafafa",
          }}
        >
          {isSuggestionsAvailable &&
            contentOpen &&
            data?.suggestions.map((item: any) => {
              return <SuggestedContent key={item.id} data={item} />;
            })}
        </Col>
      </div>
    </Radio.Group>
  );
}
