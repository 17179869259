import React from "react";
import { Footer } from "./Footer";
import { useState } from "react";
import { Col, Collapse } from "antd";
import reloadIcon from "../assets/autorenew.svg";
import { CustomPanelHeader } from "./CustomHeader";
import errorOutlinedIcon from "../assets/Error outline.svg";
import { NonClaimSuggestedContent } from "./NonClaimSuggestedContent";

interface Data {
  id: string;
  text: string;
  type: string;
}

export function NonClaimIdentificationWithouthTranslationDark({
  data,
  activeKey = 0,
  Index,
  setSelectedItemIndices,
  selectedItemIndices,
}: {
  data: Data;
  activeKey?: number;
  Index?: number;
  setSelectedItemIndices?: Function;
  selectedItemIndices?: number[];
}) {
  const [contentOpen, setContentOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const items = [
    {
      key: "1",
      label: (
        <CustomPanelHeader
          data={data}
          id={
            data.id ? (
              <span>
                <span style={{ color: "blue" }}>{data.id}</span>
              </span>
            ) : (
              <>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img src={errorOutlinedIcon} style={{ marginLeft: "8px" }} />
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                    }}
                  >
                    Claim not found in VVPM
                    <img src={reloadIcon} style={{ marginLeft: "8px" }} />
                  </span>
                </span>
              </>
            )
          }
          tag={data["type"]}
          title={data["text"]}
        />
      ),
    },
  ];
  return (
    <div
      style={{
        border: "1px solid lightGray",
        borderRadius: "10px",

        height: "100%",
      }}
    >
      <Collapse
        items={items}
        expandIconPosition="end"
        bordered={false}
        onChange={
          selectedItemIndices
            ? () =>
                setSelectedItemIndices(prevIndices => {
                  if (prevIndices.includes(Index)) {
                    return prevIndices.filter((item: number) => item !== Index);
                  } else {
                    return [...prevIndices, Index];
                  }
                })
            : () => setExpanded(!expanded)
        }
        activeKey={
          selectedItemIndices ? (selectedItemIndices.includes(Index) ? 1 : 0) : expanded ? 1 : 0
        }

        // {...(setSelectedBox && { onChange: () => setSelectedBox(Index) })}

        // {...(activeKey && { activeKey })}
      />
      <Footer
        onClick={() => null}
        tag="VVPM"
        rating="98%"
        btnName={contentOpen ? "Hide Suggestions" : "Load More Suggestions"}
      />

      <Col>{contentOpen && <NonClaimSuggestedContent data={data} />}</Col>
    </div>
  );
}
