import axios from "axios";
export function getTargetLanguages() {
  const endpoint = "https://replicator-dev.devkraft.in/api/parameters/language";
  return axios.get(endpoint);
}

export function getAssetElements() {
  const endpoint = "https://replicator-dev.devkraft.in/api/assets/elements";
  return axios.get(endpoint);
}
