import { useRef, useEffect } from "react";

export const useSyncedScroll = (isModalOpen: boolean) => {
  const firstRef = useRef<HTMLDivElement>(null);
  const secondRef = useRef<HTMLDivElement>(null);

  const syncScroll = (source: HTMLDivElement, target: HTMLDivElement) => {
    if (target) {
      target.scrollTop = source.scrollTop;
    }
  };

  useEffect(() => {
    if (!isModalOpen) return;

    const firstDiv = firstRef.current;
    const secondDiv = secondRef.current;

    if (!firstDiv || !secondDiv) return;

    const handleFirstScroll = () => syncScroll(firstDiv, secondDiv);
    const handleSecondScroll = () => syncScroll(secondDiv, firstDiv);

    firstDiv.addEventListener("scroll", handleFirstScroll);
    secondDiv.addEventListener("scroll", handleSecondScroll);

    return () => {
      firstDiv.removeEventListener("scroll", handleFirstScroll);
      secondDiv.removeEventListener("scroll", handleSecondScroll);
    };
  }, [isModalOpen]);

  return { firstRef, secondRef };
};
