import React from "react";
import AppHeader from "./Header/Hearder";
import Sidebar from "./Sidebar/Sidebar";
import Layout from "antd/es/layout/layout";

const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <AppHeader />
      <Layout>
        <Sidebar />
        <div style={{ height: "100%", width: "100%" }}>{children}</div>
      </Layout>
    </div>
  );
};

export default DefaultLayout;
