import { GlobalOutlined } from "@ant-design/icons";
import CustomDropDownSelect from "../../components/CustomDropDownSelect/CustomDropDownSelect";

const marketOptions = [
  { label: "US", value: "us" },
  { label: "EU", value: "eu" },
  { label: "India", value: "india" },
  { label: "Japan", value: "japan" },
  { label: "Spain", value: "spain" },
];

const LocalisationMarketSelect: React.FC = () => {
  return (
    <div>
      <CustomDropDownSelect
        title="Market"
        icon={<GlobalOutlined style={{ fontSize: "16px", marginRight: "8px" }} />}
        options={marketOptions}
      />
    </div>
  );
};

export default LocalisationMarketSelect;
