import React from "react";
import { Tag } from "antd";
import { RobotOutlined } from "@ant-design/icons";

interface AIGeneratedTagProps {
  percentage?: number;
}

export default function AIGeneratedTag({ percentage }: AIGeneratedTagProps = {}) {
  return (
    <Tag
      icon={<RobotOutlined />}
      style={{
        backgroundColor: "#F3E8FF",
        color: "#7B3FE4",
        border: "none",
        borderRadius: "16px",
        padding: "2px 8px",
        display: "inline-flex",
        alignItems: "center",
        gap: "4px",
        fontSize: "12px",
        fontWeight: 500,
      }}
    >
      AI Generated
      {percentage !== undefined && (
        <span style={{ marginLeft: "4px", fontWeight: "normal" }}>{percentage}%</span>
      )}
    </Tag>
  );
}
