import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Button } from "antd";
import { CaretUpOutlined, CaretDownOutlined, CloseOutlined } from "@ant-design/icons";
import "./CustomDropDownSelect.css";
import { useAppDispatch } from "../../../../store/hooks";
import { setCurrentStepLoading } from "../../../../store/slices/contentReplicatorSlice";

interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  title: string;
  icon: React.ReactNode;
  options: SelectOption[];
  selectedIcon?: React.ReactNode;
}

const CustomDropDownSelect: React.FC<SelectProps> = ({ title, icon, options, selectedIcon }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const currentElRef = useRef<HTMLDivElement>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentStepLoading(true));
  }, []);

  useEffect(() => {
    dispatch(setCurrentStepLoading(!(selectedItems.length > 0)));
  }, [selectedItems]);

  useEffect(() => {
    if (currentElRef.current) {
      setTimeout(() => {
        currentElRef.current &&
          currentElRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
    }
  }, [currentElRef.current, dropdownVisible]);

  const handleSelectItem = (value: string) => {
    setSelectedItems(prevSelected =>
      prevSelected.includes(value)
        ? prevSelected.filter(item => item !== value)
        : [...prevSelected, value],
    );
  };

  const handleClearAll = () => {
    setSelectedItems([]);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const isSelected = (value: string) => selectedItems.includes(value);

  return (
    <div className="custom-select-container" ref={currentElRef}>
      <div className="custom-select-header" onClick={toggleDropdown}>
        <span className="custom-select-title">
          {icon}
          <b>{title}*</b>
          {selectedItems.length > 0 ? (
            <span className="custom-select-selected-count">
              : {selectedItems.length} {title}
              {selectedItems.length > 1 ? "s" : ""} Selected
            </span>
          ) : (
            <span className="custom-select-placeholder"> : Search and Select</span>
          )}
        </span>
        <span className="custom-select-icon">
          {dropdownVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </span>
      </div>

      {dropdownVisible && (
        <div className="custom-select-dropdown">
          {options.map(option => (
            <div key={option.value} className="custom-select-option">
              <Checkbox
                checked={isSelected(option.value)}
                onChange={() => handleSelectItem(option.value)}
              >
                {option.label}
              </Checkbox>
            </div>
          ))}
        </div>
      )}

      {selectedItems.length > 0 && (
        <div className="custom-select-selected-container">
          {selectedItems.map(item => (
            <span key={item} className="custom-select-selected-item">
              {selectedIcon}
              {options.find(option => option.value === item)?.label}
              <CloseOutlined
                className="custom-select-close-icon"
                onClick={() => handleSelectItem(item)}
              />
            </span>
          ))}
          <Button type="link" onClick={handleClearAll} className="custom-select-clear-button">
            Clear All
          </Button>
        </div>
      )}
    </div>
  );
};

export default CustomDropDownSelect;
