import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ConversationStep,
  SelectedAssetParameters,
} from "../../pages/Content Replicator/resources/types";
import {
  CONVERSATION_STEPS,
  ConversationStatus,
  StepperInitalState,
  StepperStepStatus,
} from "../../pages/Content Replicator/resources/constants";
import { StepProps } from "antd";
import { formatDateTime } from "../../utils/helpers";

export interface ContentReplicatorState {
  stepperCurrentStep: number;
  stepperSteps: StepProps[];
  conversationSteps: ConversationStep[];
  currentStep: number;
  currentStepLoading: boolean;
  selectedAssetParameters: SelectedAssetParameters;
  selectedArtefactId: string;
}

const initialState: ContentReplicatorState = {
  stepperCurrentStep: 0,
  stepperSteps: StepperInitalState,
  conversationSteps: CONVERSATION_STEPS,
  currentStep: 1,
  currentStepLoading: false,
  selectedAssetParameters: {
    channel: "",
    brand: "",
    therapeuticArea: "",
    market: "",
    language: "",
    keyMessage: "",
  },
  selectedArtefactId: "1adFwef",
};

export const contentReplicatorSlice = createSlice({
  name: "contentReplicator",
  initialState,
  reducers: {
    setStepperCurrentStep: (state, action: PayloadAction<number>) => {
      state.stepperCurrentStep = action.payload;
    },
    setStepperSteps: (state, action: PayloadAction<StepProps[]>) => {
      state.stepperSteps = action.payload;
    },
    setCurrentStepperStepFinish: state => {
      return {
        ...state,
        stepperSteps: state.stepperSteps.map((s, i) =>
          i === state.stepperCurrentStep ? { ...s, status: StepperStepStatus.FINISH } : s,
        ),
      };
    },
    setCurrentStepperStepFinishNextStepProcess: state => {
      return {
        ...state,
        stepperSteps: state.stepperSteps.map((s, i) =>
          i === state.stepperCurrentStep
            ? { ...s, status: StepperStepStatus.FINISH }
            : i === state.stepperCurrentStep + 1
              ? { ...s, status: StepperStepStatus.PROCESS }
              : s,
        ),
        stepperCurrentStep: state.stepperCurrentStep + 1,
      };
    },
    setConversationSteps: (state, action: PayloadAction<ConversationStep[]>) => {
      state.conversationSteps = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    // setConversationStatus: (state, action: PayloadAction<ConversationStatus>) => {
    //   state.conversationStatus = action.payload;
    // },
    setCurrentStepDone: state => {
      return {
        ...state,
        conversationSteps: state.conversationSteps.map(cs =>
          cs.step === state.currentStep ? { ...cs, status: ConversationStatus.DONE } : cs,
        ),
      };
    },
    setCurrentStepDoneNextStepProcess: state => {
      return {
        ...state,
        conversationSteps: state.conversationSteps.map(cs =>
          cs.step === state.currentStep
            ? { ...cs, status: ConversationStatus.DONE }
            : cs.step === state.currentStep + 1
              ? { ...cs, status: ConversationStatus.PROCESS, timestamp: formatDateTime() }
              : cs,
        ),
        currentStep: state.currentStep + 1,
      };
    },
    setCurrentStepDoneGivenStepProcess: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        conversationSteps: state.conversationSteps.map(cs =>
          cs.step === state.currentStep
            ? { ...cs, status: ConversationStatus.DONE }
            : cs.step === action.payload
              ? { ...cs, status: ConversationStatus.PROCESS }
              : cs,
        ),
        currentStep: action.payload,
      };
    },
    setCurrentStepLoading: (state, action: PayloadAction<boolean>) => {
      state.currentStepLoading = action.payload;
    },
    setCurrentStepTimestamp: (state, action: PayloadAction<string | undefined>) => {
      const timestamp = action.payload ?? formatDateTime();
      state.conversationSteps[state.currentStep - 1].timestamp = timestamp;
    },
    setSelectedAssetParameters: (state, action: PayloadAction<SelectedAssetParameters>) => {
      state.selectedAssetParameters = action.payload;
    },
    setSelectedArtefactId: (state, action: PayloadAction<string>) => {
      state.selectedArtefactId = action.payload;
    },
    resetCRStates: () => initialState,
  },
});

export const {
  setStepperSteps,
  setStepperCurrentStep,
  setCurrentStepperStepFinish,
  setCurrentStepperStepFinishNextStepProcess,
  setConversationSteps,
  setCurrentStep,
  setCurrentStepDone,
  setCurrentStepDoneNextStepProcess,
  setCurrentStepDoneGivenStepProcess,
  setCurrentStepLoading,
  setCurrentStepTimestamp,
  setSelectedAssetParameters,
  setSelectedArtefactId,
  resetCRStates,
} = contentReplicatorSlice.actions;

export default contentReplicatorSlice.reducer;
