export function truncateText(text: string, maxLength: number): string {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}

export const formatDateTime = (date = new Date()) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    // throw new Error("formatDateTime received invalid date object");
    return "2:04 PM, 15 Aug";
  }

  const hours = (date.getHours() % 12 || 12).toString().padStart(2, "0"); // 12-hour format
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });

  return `${hours}:${minutes} ${ampm}, ${day} ${month}`; // "2:04 PM, 15 Nov"
};
