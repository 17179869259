import React, { useState } from "react";
import { Card, Row, Col, notification } from "antd";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "../../routes/routePaths";
import { ASSETS } from "../../assets/assetsSrcMap";
import "../Content Transcreation/Predis.css";

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  index: number;
  onClick?: () => void;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description, index, onClick }) => {
  const navigate = useNavigate();

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        background: "#fff",
        display: "flex",
        flexDirection: "row", // Changed to row to align icon and text horizontally
        alignItems: "center",
        cursor: "pointer ",
      }}
      onClick={() => {
        index === 0 && navigate(ROUTE_PATHS.CONTENT_REPLICATOR);
        // index === 3 && (window.location.href = "https://genai.indegene.com/next-gist"); //Brand IQ/Semantic Search
        index === 3 &&
          window.open("https://genai.indegene.com/next-gist", "_blank", "noopener, noreferrer"); //Brand IQ/Semantic Search

        index === 1 && onClick(); //PREDIS AI
      }}
    >
      <div
        style={{
          backgroundColor: "#F3E8FF",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 16px 14px 0",
        }}
      >
        {icon}
      </div>
      <div>
        <h3 style={{ marginBottom: "4px" }}>{title}</h3>
        <p style={{ color: "#8c8c8c", fontSize: "13px", margin: 0 }}>{description}</p>
      </div>
    </Card>
  );
};

const cardData = [
  {
    icon: (
      <img
        src={ASSETS.DASHBOARD_DYNAMIC_FEED_ICON}
        style={{ fontSize: "24px", color: "#722ed1" }}
      />
    ),
    title: "Derivative Content AI",
    description: "Effortlessly create derivative content across markets",
  },
  {
    icon: <img src={ASSETS.DASHBOARD_EDIT_ICON} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "New Content AI",
    description: "Instructs to create compliant content",
  },
  {
    icon: (
      <img
        src={ASSETS.DASHBOARD_ACCOUNT_AVATAR_ICON}
        style={{ fontSize: "24px", color: "#722ed1" }}
      />
    ),
    title: "PRC AI",
    description: "Expedites content approval while ensuring compliance",
  },
  {
    icon: (
      <img
        src={ASSETS.DASHBOARD_PSYCHOLOGY_INSIGHT_ICON}
        style={{ fontSize: "24px", color: "#722ed1" }}
      />
    ),
    title: "Brand IQ",
    description: "Derives actionable brand insights",
  },
  {
    icon: <img src={ASSETS.DASHBOARD_EDIT_ICON} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "Creative AI Lab",
    description: "One-stop solution for all creative needs",
  },
  {
    icon: (
      <img
        src={ASSETS.DASHBOARD_EMOJI_INSIGHT_ICON}
        style={{ fontSize: "24px", color: "#722ed1" }}
      />
    ),
    title: "Insights Genie",
    description: "Delivers tailored analytics with conversational insights",
  },
  // {
  //   icon: <img src={ASSETS.DASHBOARD_EDIT_ICON} style={{ fontSize: "24px", color: "#722ed1" }} />,
  //   title: "MLR Acceleration",
  //   description: "Expedites content approvals"
  // },
  // {
  //   icon: <img src={ASSETS.DASHBOARD_EMOJI_INSIGHT_ICON} style={{ fontSize: "24px", color: "#722ed1" }} />,
  //   title: "Gen AI Playground",
  //   description: "Explores Gen AI capabilities in a domain context"
  // }
];

const FeatureCards: React.FC = () => {
  // const [openPredisDialog, setOpenPredisDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openPredisAIPopup = () => {
    setIsLoading(true);
    //@ts-ignore
    const predis = new window.Predis();

    predis.initialize({
      appId: "2ac15VN8a3F3S0AGHNVI8cjV7mU4V99N",
      embedToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIyYWMxNVZOOGEzRjNTMEFHSE5WSThjalY3bVU0Vjk5TiIsInVzZXJfaWQiOiJwcmF0ZWVrLmphaW5AaW5kZWdlbmUuY29tIiwiaWF0IjoxNzAxMTUxMDU3fQ.rDThcHWFyAlGfxoKAUPyRjVKJQ-nqWtw8Hwk11I4OmU",
      //@ts-ignore
      mode: window.Predis.modes.DETAILED,
    });

    predis.on("ready", () => {
      // open Predis.ai post creator
      console.log("Ready!");
      setIsLoading(false); // Stop showing the loading overlay

      activatePredisFullScreen();

      predis.createPost({
        onPostPublish: function (err: any, data: any) {
          if (err) {
            console.log("Error Occurred in Posting", err);
            notification.error({ message: "Error Occurred in Posting" });
            return;
          }
          notification.success({ message: "Post created successfully" });
          // published posts' data
          console.log({ data });
        },
      });
    });

    predis.on("error", (err: any) => {
      console.log({ err });
      notification.error({ message: "Error Occurred in Predis SDK Initialization" });
      // catch if there is any error in SDK initialization
      setIsLoading(false);
    });
  };

  const activatePredisFullScreen = () => {
    let timer = setInterval(() => {
      const elmParentDiv = document.getElementsByTagName("iframe")?.[0]?.parentElement;

      if (elmParentDiv) {
        elmParentDiv.className = "predis-dialog";
        clearInterval(timer);
      }
    }, 1000);
  };

  return (
    <div style={{ padding: "24px 50px" }}>
      <Row gutter={[16, 16]} justify="start">
        {cardData.map((card, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={8}>
            <FeatureCard
              icon={card.icon}
              title={card.title}
              description={card.description}
              index={index}
              onClick={openPredisAIPopup}
            />
          </Col>
        ))}
      </Row>
      {/* {openPredisDialog && <PredisDialog resetDialogState={() => setOpenPredisDialog(false)} />} */}
      {isLoading ? <LoadingOverlay /> : null}
    </div>
  );
};

export default FeatureCards;

const LoadingOverlay: React.FC = () => {
  return (
    <div style={overlayStyle}>
      <div style={spinnerStyle}>Loading...</div>
    </div>
  );
};

const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const spinnerStyle: React.CSSProperties = {
  fontSize: "24px",
  color: "#fff",
};
