import React from "react";
import { Collapse } from "antd";
import { Children } from "./Children";
import { CustomPanelHeader } from "./CustomHeader";
import reloadIcon from "../assets/autorenew.svg";
import errorOutlinedIcon from "../assets/Error outline.svg";

export function ClaimIdentificationWithouthTranslation({
  data,
  Index,
  setSelectedItemIndices,
  selectedItemIndices,
}: any) {
  const [expanded, setExpanded] = React.useState(false);
  const items = [
    {
      key: "1",
      label: (
        <CustomPanelHeader
          id={
            data?.id ? (
              <span>
                <span style={{ color: "blue" }}>{data?.id}</span>
              </span>
            ) : (
              <>
                <span style={{ display: "flex", alignItems: "center" }}>
                  PromoMats ID:
                  <img src={errorOutlinedIcon} style={{ marginLeft: "8px" }} />
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                    }}
                  >
                    Claim not found in VVPM
                    <img src={reloadIcon} style={{ marginLeft: "8px" }} />
                  </span>
                </span>
              </>
            )
          }
          tag={data?.["key_message"]}
          title={data?.name}
        />
      ),
      children: <Children references={data?.reference} footnotes={data?.footnote} />,
    },
  ];
  return (
    <div
      style={{
        border: "1px solid lightGray",
        borderRadius: "10px",
        height: "100%",
      }}
    >
      <Collapse
        items={items}
        expandIconPosition="end"
        ghost
        onChange={
          setSelectedItemIndices
            ? () =>
                setSelectedItemIndices(prevIndices => {
                  if (prevIndices.includes(Index)) {
                    return prevIndices.filter((item: number) => item !== Index);
                  } else {
                    return [...prevIndices, Index];
                  }
                })
            : () => {
                setExpanded(!expanded);
              }
        }
        activeKey={
          selectedItemIndices ? (selectedItemIndices?.includes(Index) ? 1 : 0) : expanded ? 1 : 0
        }
      />
    </div>
  );
}
