import { useState } from "react";
import LocalisedClaimModal from "../../../../ss-dev/slide52/LocalisedClaimModal";
import LocalisedNonClaimModal from "../../../../ss-dev/slide52/LocalisedNonClaimModal";
import { StepWiseProgress } from "../../components/Conversation/Loaders/StepWiseProgress";
import Component from "../../../../ss-dev/slide55/PersonalisationModal";
import AssemblyModal from "../../../../ss-dev/slide57/AssemblyModal";

const stepsData = [
  { id: 0, label: "Derivative Asset Creation with personalization (with translation)" },
  { id: 1, label: "Equivalent Claims Personalisation" },
  { id: 2, label: "Personalised reusable text (Non-claims) generated" },
  { id: 3, label: "Personalised Image/Graphic component" },
  {
    id: 4,
    label: "Personalized derivative asset creation with personalization (with translation)",
  },
];

export const PersonalisationWithTranslationLocalisation = () => {
  const [openedModalId, setOpenedModalId] = useState(0);

  return (
    <>
      <StepWiseProgress steps={stepsData} viewButtons={true} setOpenedModalId={setOpenedModalId} />
      <LocalisedClaimModal
        open={openedModalId === 1}
        setOpen={setOpenedModalId}
        withTranslation={true}
      />
      <LocalisedNonClaimModal
        open={openedModalId === 2}
        setOpen={setOpenedModalId}
        withTranslation={true}
      />
      {/* Images */}
      <Component open={openedModalId === 3} setOpen={setOpenedModalId} />
      <AssemblyModal open={openedModalId === 4} setOpen={setOpenedModalId} withTranslation={true} />
    </>
  );
};
