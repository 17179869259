import { CustomStepper } from "./components/Stepper/Stepper";
import { CRContentArea } from "./CRContentArea";

export const ContentReplicator: React.FC = () => {
  return (
    <div className="content-replicator" style={{ display: "flex", height: "100%" }}>
      <div style={{ padding: "32px", borderRight: "1px solid gray" }}>
        <CustomStepper />
      </div>
      <CRContentArea />
    </div>
  );
};
