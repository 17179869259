import { TranslationOutlined } from "@ant-design/icons";
import CustomDropDownSelect from "../../components/CustomDropDownSelect/CustomDropDownSelect";

const languageOptions = [
  { label: "Arabic", value: "ar" },
  { label: "French", value: "fr" },
  { label: "Japanese", value: "jp" },
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
];

export const TranslationLanguageSelect: React.FC = () => {
  return (
    <div>
      <CustomDropDownSelect
        title="Language"
        icon={<TranslationOutlined style={{ fontSize: "16px", marginRight: "8px" }} />}
        options={languageOptions}
      />
    </div>
  );
};
