import React from "react";
import { Button, Form, Input, Card, Image } from "antd";
import { useAuth } from "../../context/AuthContext";
import { ASSETS } from "../../assets/assetsSrcMap";

const LoginScreen: React.FC = () => {
  const { login } = useAuth();
  const onFinish = async (values: { username: string; password: string }) => {
    console.log("Success:", values);
    try {
      const response = await Promise.resolve({ data: { token: "11111" } });
      const { token } = response.data;
      //   const response = await axios.post("/api/login", { username, password });
      //   const { token } = response.data;
      login(token);
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        style={{
          width: 300,
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          <Image src={ASSETS.APP_LOGO} preview={false} width={120} alt="App Logo" />
        </div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input placeholder="Enter your username" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginScreen;
