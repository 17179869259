import { Button, StepProps, Steps } from "antd";
import { useEffect, useState } from "react";
import "./Stepper.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  ConversationStepsMapping,
  StepperConversationStepsMapping,
  StepperSteps,
} from "../../resources/constants";
import {
  setCurrentStepperStepFinishNextStepProcess,
  setStepperCurrentStep,
} from "../../../../store/slices/contentReplicatorSlice";

const initalSteps: StepProps[] = [
  {
    title: "Input Details",
    status: "process", //"finish",
  },
  {
    title: "Output Specifications",
    status: "wait",
  },
  {
    title: "Output Processing",
    status: "wait",
  },
  {
    title: "MLR Support",
    status: "wait",
  },
  {
    title: "MLR Submission",
    status: "wait",
  },
  {
    title: "Ready for Publish",
    status: "wait",
  },
];

export const CustomStepper = () => {
  // const [currentStep, setCurrentStep] = useState(0);
  const { stepperCurrentStep, stepperSteps, currentStep } = useAppSelector(
    state => state.contentReplicator,
  );
  const [finishedStep, setFinishedStep] = useState(-1); //Update this on Next click
  // const [steps, setSteps] = useState(initalSteps);

  const dispatch = useAppDispatch();

  // const nextStep = () => {
  //   setCurrentStep(prev => (prev < steps.length - 1 ? prev + 1 : prev));
  // };

  console.log({ stepperCurrentStep });
  useEffect(() => {
    const currentStepperKeyRange = "sdfsd";
    const currentStepperKeyConversationStepRangeNames =
      StepperConversationStepsMapping[StepperSteps[stepperCurrentStep]];

    let lowerBound: number, upperBound: number;
    Object.entries(ConversationStepsMapping).find(([key, value]) => {
      if (value.includes(currentStepperKeyConversationStepRangeNames[0])) {
        lowerBound = Number(key);
      }
      if (value.includes(currentStepperKeyConversationStepRangeNames[1])) {
        upperBound = Number(key);
      }
    });

    console.log({ lowerBound, upperBound, currentStepperKeyConversationStepRangeNames });

    if (upperBound) {
      if (currentStep > upperBound) dispatch(setCurrentStepperStepFinishNextStepProcess());
    }
  }, [currentStep]);

  const onStepChange = (newStep: number) => {
    // if (newStep <= finishedStep + 1) setCurrentStep(newStep);
    // setSteps(prevSteps =>{
    //   const updatedSteps = [...prevSteps];
    //   updatedSteps[newStep] =
    // })
  };

  return (
    <>
      <Steps
        className="cr-stepper"
        items={stepperSteps}
        direction="vertical"
        current={stepperCurrentStep}
        size="small"
        onChange={onStepChange}
      />
      {/* <Button onClick={nextStep}>Next</Button> */}
    </>
  );
};

{
  /* <Button onClick={nextStep}>Next</Button> */
}
