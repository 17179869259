import { Button, Tag } from "antd";

export const Footer: React.FC<{
  tag: string;
  rating: string;
  btnName: string;
  onClick: () => void;
}> = ({ tag, rating, btnName, onClick }) => {
  return (
    <div
      style={{
        background: "#FAFAFA",
        display: "flex",
        padding: "1rem",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Tag style={{ backgroundColor: "RGB(254, 230, 199)" }}>{tag}</Tag>
        <Tag style={{ backgroundColor: "white" }}>
          <span style={{ color: "green" }}>{rating}</span> Match Rate
        </Tag>
      </div>
      <Button onClick={onClick}>{btnName}</Button>
    </div>
  );
};
