export const ASSETS = {
  APP_LOGO: "/assets/icons/IntelReg.svg",
  HEADER_NOTIFICATION: "/assets/icons/NotificationIcon.svg",
  HEADER_AVATAR: "/assets/icons/HeaderAvatarImage.png",

  WELCOME_SCREEN_HEADER_IMAGE: "/assets/icons/dashboard/WelcomeScreenHeader.jpg",
  DASHBOARD_ACCOUNT_AVATAR_ICON: "/assets/icons/dashboard/account_circle.svg",
  DASHBOARD_DYNAMIC_FEED_ICON: "/assets/icons/dashboard/dynamic_feed.svg",
  DASHBOARD_EDIT_ICON: "/assets/icons/dashboard/edit.svg",
  DASHBOARD_EMOJI_INSIGHT_ICON: "/assets/icons/dashboard/emoji_insight.svg",
  DASHBOARD_PSYCHOLOGY_INSIGHT_ICON: "/assets/icons/dashboard/psychology.svg",

  SIDEBAR_CONTENT_REPLICATOR_ICON: "/assets/icons/sidebar/ContentReplicatorIcon.svg",
  SIDEBAR_REPORTS_ICON: "/assets/icons/sidebar/ReportsIcon.svg",
  SIDEBAR_REARRANGE_ICON: "/assets/icons/sidebar/RearrangeIcon.svg",
  SIDEBAR_SETTINGS_ICON: "/assets/icons/sidebar/SettingsIcon.svg",
  CONTENT_REPLICATOR_MODULE_ICON: "/assets/icons/content-replicator/DynamicFeed.svg",
  CR_ADMIN_NEXT_ICON: "/assets/icons/content-replicator/AdminNextIcon.svg",
  CR_EDIT_MESSAGE_ICON: "/assets/icons/content-replicator/EditMessageIcon.svg",
  CR_PIN_MESSAGE_ICON: "/assets/icons/content-replicator/PinMessageIcon.svg",
  CR_USER_AVATAR_ICON: "/assets/icons/content-replicator/UserAvatarIcon.png",
};
