import { EndPoints, Services } from "./api/apiData";
import ApiService from "./api/apiService";
import cancellationManager from "./api/cancellationManager";

const CRService = new ApiService(Services.CONTENT_REPLICATOR);

export const getPersonas = async (key: string) => {
  const controller = cancellationManager.createAbortController(key);

  try {
    const response = await CRService.get(EndPoints.CR_PERSONA, {
      signal: controller.signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.name === "AbortError") {
      console.log("Request aborted", error.message);
    } else {
      throw error;
    }
  }
};
export const getLocalisedAssets = async (assetId: string, key?: string) => {
  const controller = key ? cancellationManager.createAbortController(key) : undefined;
  try {
    const response = await CRService.get(EndPoints.CR_LOCALISED_ASSETS + `/${assetId}`, {
      signal: controller?.signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.name === "AbortError") {
      console.log("Request aborted", error.message);
    } else {
      throw error;
    }
  }
};
export const getTranslatedAssets = async (assetId: string, key?: string) => {
  const controller = key ? cancellationManager.createAbortController(key) : undefined;
  try {
    const response = await CRService.get(EndPoints.CR_TRANSLATED_ASSETS + `/${assetId}`, {
      signal: controller?.signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.name === "AbortError") {
      console.log("Request aborted", error.message);
    } else {
      throw error;
    }
  }
};
export const getPersonalisedAssets = async (assetId: string, key?: string) => {
  const controller = key ? cancellationManager.createAbortController(key) : undefined;
  try {
    const response = await CRService.get(EndPoints.CR_PERSONALISED_ASSETS + `/${assetId}`, {
      signal: controller?.signal,
    });
    return response.data;
  } catch (error: any) {
    if (error.name === "AbortError") {
      console.log("Request aborted", error.message);
    } else {
      throw error;
    }
  }
};
export const getPersonalisedTranslatedAssets = async (
  assetId: string,
  language: string = "Spanish",
  key?: string,
) => {
  const controller = key ? cancellationManager.createAbortController(key) : undefined;
  try {
    const response = await CRService.get(
      EndPoints.CR_PERSONALISED_ASSETS + `/${assetId}?language=${language}`,
      {
        signal: controller?.signal,
      },
    );
    return response.data;
  } catch (error: any) {
    if (error.name === "AbortError") {
      console.log("Request aborted", error.message);
    } else {
      throw error;
    }
  }
};
