import { StepProps } from "antd";
import { ConversationStep, StepperStatusKeys } from "./types";
import { formatDateTime } from "../../../utils/helpers";

export const FooterButtonsText = {
  NEXT: "Next",
  UPLOAD_INSTEAD: "Upload Instead",
  PROCEED: "Proceed",
  SKIP: "Skip",
  CANCEL: "Cancel",
  DEFINE_FOR_ENGLISH: "Define for English",
  GENERATE: "Generate",
  PUBLISH: "Publish",
  SUBMIT_SELECTED_ASSETS: "Send Selected for MLR Review",
};

export const ConversationStatus = {
  DONE: "Done",
  PROCESS: "Process",
  WAIT: "Wait",
} as const;

export const StepperStepStatus: Record<StepperStatusKeys, StepProps["status"]> = {
  WAIT: "wait",
  PROCESS: "process",
  FINISH: "finish",
  ERROR: "error",
};

export const UserType = {
  ADMIN: "admin",
  USER: "user",
};

export const ConversationStepsName = {
  ASSET_PARAMETERS_SELECTION: "Asset Parameters Selection",
  ARTEFACTS_SOURCE_SELECTION: "Artefacts Source Selection",
  USER_SEARCH_VVPM: "User Search VVPM",
  ARTEFACTS_LISTING: "Artefacts Listing",
  LOCALISATION_MARKET_SELECTION: "Localisation Market Selection",
  TRANSLATION_LANGUAGE_SELECTION: "Translation Language Selection",
  PERSONA_SELECTION: "Persona Selection",
  PERSONA_SELECTION_ENGLISH: "Persona Selection English",
  USER_GENERATE_ASSET: "User Generate Asset",
  ASSET_ELEMENT_IDENTIFIED: "Asset Element Identified",
  USER_PROCEED_TO_LOCALISATION: "User Proceed To Localisation",
  LOCALISATION_WITHOUT_TRANSLATION: "Localisation Without Translation",
  USER_PROCEED_TO_TRANSLATION: "User Proceed To Translation",
  LOCALISATION_WITH_TRANSLATION: "Localisation With Translation",
  USER_PROCEED_TO_PERSONALISATION_WITHOUT_TRANSLATION:
    "User Proceed To Personalisation Without Translation",
  PERSONALISATION_WITHOUT_TRANSLATION_AND_LOCALISATION:
    "Personalisation Without Translation And Localisation",
  USER_PROCEED_TO_PERSONALISATION_WITH_TRANSLATION:
    "User Proceed To Personalisation With Translation",
  PERSONALISATION_WITH_TRANSLATION_AND_LOCALISATION:
    "Personalisation With Translation And Localisation",
  USER_PROCEED_TO_MLR_SUBMISSION: "User Proceed To MLR Submission",
  MLR_ASSETS_SUBMISSION: "MLR Assets Submission",
  USER_SEND_ASSETS_MLR_REVIEW: "User Send Assets To MLR Review",
  // MLR_REVIEW: "MLR Review",
  MLR_ASSETS_APPROVED: "MLR Assets Approved",
};

export const ConversationStepsMapping: Record<string, string> = {
  "1": ConversationStepsName.ASSET_PARAMETERS_SELECTION,
  "2": ConversationStepsName.ARTEFACTS_SOURCE_SELECTION,
  "3": ConversationStepsName.USER_SEARCH_VVPM,
  "4": ConversationStepsName.ARTEFACTS_LISTING,
  "5": ConversationStepsName.LOCALISATION_MARKET_SELECTION,
  "6": ConversationStepsName.TRANSLATION_LANGUAGE_SELECTION,
  "7": ConversationStepsName.PERSONA_SELECTION,
  "8": ConversationStepsName.PERSONA_SELECTION_ENGLISH,
  "9": ConversationStepsName.USER_GENERATE_ASSET,
  "10": ConversationStepsName.ASSET_ELEMENT_IDENTIFIED,
  "11": ConversationStepsName.USER_PROCEED_TO_LOCALISATION,
  "12": ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION,
  "13": ConversationStepsName.USER_PROCEED_TO_TRANSLATION,
  "14": ConversationStepsName.LOCALISATION_WITH_TRANSLATION,
  "15": ConversationStepsName.USER_PROCEED_TO_PERSONALISATION_WITHOUT_TRANSLATION,
  "16": ConversationStepsName.PERSONALISATION_WITHOUT_TRANSLATION_AND_LOCALISATION,
  "17": ConversationStepsName.USER_PROCEED_TO_PERSONALISATION_WITH_TRANSLATION,
  "18": ConversationStepsName.PERSONALISATION_WITH_TRANSLATION_AND_LOCALISATION,
  "19": ConversationStepsName.USER_PROCEED_TO_MLR_SUBMISSION,
  "20": ConversationStepsName.MLR_ASSETS_SUBMISSION,
  "21": ConversationStepsName.USER_SEND_ASSETS_MLR_REVIEW,
  // "22": ConversationStepsName.MLR_REVIEW,
  "22": ConversationStepsName.MLR_ASSETS_APPROVED,
};

export const LastConversationStep = Math.max(...Object.keys(ConversationStepsMapping).map(Number));

// export const LastConversationStep = Object.keys(ConversationStepsMapping).reduce(
//   (last, current) => (parseInt(current) > last ? parseInt(current) : last),
//   1,
// );

export const ConversationStepsMessages: Record<
  string,
  string | { first?: string; second?: string }
> = {
  [ConversationStepsName.ASSET_PARAMETERS_SELECTION]:
    "Let's start by defining the following asset parameters:",
  [ConversationStepsName.ARTEFACTS_SOURCE_SELECTION]:
    "Share a reference artefact that you would want to replicate, I have integration with Veeva Vault PromoMats. Do you want to pull assets or upload your assets?",
  [ConversationStepsName.USER_SEARCH_VVPM]: "Search From VVPM",
  [ConversationStepsName.ARTEFACTS_LISTING]:
    "Based on the above details we have found some existing artefacts that in the <b>Veeva Vault PromoMats</b>. Select one of the assets for replication.",
  [ConversationStepsName.LOCALISATION_MARKET_SELECTION]: `Perfect! Now lets define the <b>output specifications</b>. This includes:<br/><br/><b>Content Creation:</b> Set your target markets`,
  [ConversationStepsName.TRANSLATION_LANGUAGE_SELECTION]: `Ok! Please specify the languages in which you want the assets to be created in:<br/>
  <b>Translation</b> for the assets`,
  [ConversationStepsName.PERSONA_SELECTION]: {
    first: `Ok! Now lets personalise your assets as per <b>Spanish</b> Language:<br/>`,
    // <b>Personalisation:</b> Select personas for whom you want to personalise the assets.`,
    second: `<label><input type="checkbox" /> Apply the selected personas for all languages</label>`,
  }, //Spanish
  [ConversationStepsName.PERSONA_SELECTION_ENGLISH]: {
    first: `Perfect! Now lets personalise your assets as per <b>English</b> Language:`,
    second: "Would you like to proceed?",
  }, //English
  [ConversationStepsName.USER_GENERATE_ASSET]: "Generate",
  [ConversationStepsName.ASSET_ELEMENT_IDENTIFIED]: {
    second: "Would you like to proceed to Derivative Asset Creation?",
  },
  [ConversationStepsName.USER_PROCEED_TO_LOCALISATION]: "Proceed to Derivative Asset Creation",
  [ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION]: {
    second: "Would you like to proceed to translation?",
  },
  [ConversationStepsName.USER_PROCEED_TO_TRANSLATION]: "Proceed to translation",
  [ConversationStepsName.LOCALISATION_WITH_TRANSLATION]: {
    second: "Would you like to proceed to personalisation?",
  },
  [ConversationStepsName.USER_PROCEED_TO_PERSONALISATION_WITHOUT_TRANSLATION]:
    "Proceed to Derivative asset creation with personalization (without translation)",
  [ConversationStepsName.PERSONALISATION_WITHOUT_TRANSLATION_AND_LOCALISATION]: {
    second:
      "Would you like to proceed to Derivative asset creation with personalization (with translation)?",
  },
  [ConversationStepsName.USER_PROCEED_TO_PERSONALISATION_WITH_TRANSLATION]:
    "Proceed to Derivative asset creation with personalization (with translation)",
  [ConversationStepsName.PERSONALISATION_WITH_TRANSLATION_AND_LOCALISATION]: {
    second:
      // "Would you like to proceed to asset assembly and generation with localisation, translation and personalisation?",
      "Would you like to proceed to MLR Submission?",
  },
  [ConversationStepsName.USER_PROCEED_TO_MLR_SUBMISSION]: "Proceed to MLR Submission",
  [ConversationStepsName.MLR_ASSETS_SUBMISSION]: "Here are the assets that have been generated:",
  [ConversationStepsName.USER_SEND_ASSETS_MLR_REVIEW]: "Send selected assets for MLR review",
  [ConversationStepsName.MLR_ASSETS_APPROVED]: {
    second:
      "Awesome! Your request is successfully sent for MLR review. We will notify you when there is a change in your request status.",
  },
  // [ConversationStepsName.MLR_REVIEW]: "We have found the following assets in MLR:",
  // [ConversationStepsName.MLR_ASSET_APPROVED]: "Asset approved",
};

export const ConversationStepLoadingData = {
  [ConversationStepsName.ARTEFACTS_LISTING]: { text: "Searching for relevant artefacts..." },
};

export const ConversationStepsFooterButtonsText = {
  [ConversationStepsName.ASSET_PARAMETERS_SELECTION]: [FooterButtonsText.NEXT],
  [ConversationStepsName.ARTEFACTS_SOURCE_SELECTION]: [FooterButtonsText.NEXT],
  [ConversationStepsName.ARTEFACTS_LISTING]: [
    FooterButtonsText.UPLOAD_INSTEAD,
    FooterButtonsText.NEXT,
  ],
  [ConversationStepsName.LOCALISATION_MARKET_SELECTION]: [FooterButtonsText.PROCEED],
  [ConversationStepsName.TRANSLATION_LANGUAGE_SELECTION]: [
    FooterButtonsText.SKIP,
    FooterButtonsText.PROCEED,
  ],
  [ConversationStepsName.PERSONA_SELECTION]: [FooterButtonsText.DEFINE_FOR_ENGLISH],
  [ConversationStepsName.PERSONA_SELECTION_ENGLISH]: [
    FooterButtonsText.CANCEL,
    FooterButtonsText.GENERATE,
  ],
  [ConversationStepsName.ASSET_ELEMENT_IDENTIFIED]: [
    FooterButtonsText.CANCEL,
    FooterButtonsText.PROCEED,
  ],
  [ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION]: [
    FooterButtonsText.CANCEL,
    FooterButtonsText.PROCEED,
  ],
  [ConversationStepsName.LOCALISATION_WITH_TRANSLATION]: [
    FooterButtonsText.CANCEL,
    FooterButtonsText.PROCEED,
  ],
  [ConversationStepsName.PERSONALISATION_WITHOUT_TRANSLATION_AND_LOCALISATION]: [
    FooterButtonsText.CANCEL,
    FooterButtonsText.PROCEED,
  ],
  [ConversationStepsName.PERSONALISATION_WITH_TRANSLATION_AND_LOCALISATION]: [
    FooterButtonsText.CANCEL,
    FooterButtonsText.PROCEED,
  ],
  [ConversationStepsName.MLR_ASSETS_SUBMISSION]: [FooterButtonsText.SUBMIT_SELECTED_ASSETS],
};

export const conversationSteps: ConversationStep[] = [
  {
    step: 1,
    type: UserType.ADMIN, //"admin",
    timestamp: "2:04 PM, 15 Nov",
    titleMessage: "Let's start by defining the following asset parameters:",
    componentName: "AssetParametersSelection",
    footerButtonText: [FooterButtonsText.NEXT], //["next"]
    status: ConversationStatus.DONE, // "done", "process"
  },
  {
    step: 2,
    type: UserType.ADMIN, //"admin",
    timestamp: "2:04 PM, 15 Nov",
    titleMessage:
      "Share a reference artefact that you would want to replicate, I have integration with Veeva Vault PromoMats. Do you want to pull assets or upload your assets?",
    componentName: "ArtefactsSourceSelection",
    footerButtonText: [FooterButtonsText.NEXT], //["next"]
    status: ConversationStatus.DONE, // "done", "process"
  },
  {
    step: 3,
    type: UserType.USER, //"user",
    timestamp: "2:05 PM, 15 Nov",
    titleMessage: "Search From VVPM",
    status: ConversationStatus.PROCESS, // "done", "process"
  },
];

export function generateConversationSteps(): ConversationStep[] {
  const steps: ConversationStep[] = [];
  Object.entries(ConversationStepsMapping).forEach(([step, name], index) => {
    console.log({ step, name });
    let componentName = "";
    let footerButtonText: string[] = ConversationStepsFooterButtonsText[name];
    let status = index === 0 ? ConversationStatus.PROCESS : ConversationStatus.WAIT;

    const getMessages = () => {
      let firstMessage, secondMessage;
      if (!ConversationStepsMessages[name]) return [firstMessage, secondMessage];
      if (typeof ConversationStepsMessages[name] !== "string") {
        firstMessage = (ConversationStepsMessages[name] as any).first;
        secondMessage = (ConversationStepsMessages[name] as any).second;
      } else {
        firstMessage = ConversationStepsMessages[name] as string;
      }
      return [firstMessage, secondMessage];
    };

    const [titleMessage, footerMessage] = getMessages();

    steps.push({
      step: parseInt(step),
      type: name.startsWith("User") ? UserType.USER : UserType.ADMIN,
      timestamp: formatDateTime(), //"2:04 PM, 15 Nov",
      titleMessage,
      footerMessage,
      componentName,
      footerButtonText,
      status,
    });
  });

  return steps;
}

export const CONVERSATION_STEPS = generateConversationSteps();

console.log({ CONVERSATION_STEPS });

export const StepperStepsName = {
  INPUT_DETAILS: "Input Details",
  OUTPUT_SPECIFICATIONS: "Output Specifications",
  // OUTPUT_PROCESSING: "Output Processing",
  LOCALISATION: "Content Creation",
  TRANSLATION: "Translation",
  PERSONALISATION: "Personalisation",
  // MLR_SUPPORT: "MLR Support",
  MLR_SUBMISSION: "MLR Submission",
  // READY_FOR_PUBLISH: "Ready for Publish",
};
export const StepperSteps: Record<number, string> = {
  0: StepperStepsName.INPUT_DETAILS,
  1: StepperStepsName.OUTPUT_SPECIFICATIONS,
  // 2: StepperStepsName.OUTPUT_PROCESSING,
  2: StepperStepsName.LOCALISATION,
  3: StepperStepsName.TRANSLATION,
  4: StepperStepsName.PERSONALISATION,
  // 3: StepperStepsName.MLR_SUPPORT,
  5: StepperStepsName.MLR_SUBMISSION,
  // 5: StepperStepsName.READY_FOR_PUBLISH,
};

export const StepperInitalState: StepProps[] = Object.keys(StepperStepsName).reduce(
  (steps, step, index) => {
    steps.push({
      title: StepperStepsName[step as keyof typeof StepperStepsName],
      status: index === 0 ? "process" : "wait",
    });
    return steps;
  },
  [] as StepProps[],
);

console.log({ StepperInitalState });

export const StepperConversationStepsMapping: Record<string, string[]> = {
  [StepperStepsName.INPUT_DETAILS]: [
    ConversationStepsName.ASSET_PARAMETERS_SELECTION,
    ConversationStepsName.ARTEFACTS_LISTING,
  ],
  [StepperStepsName.OUTPUT_SPECIFICATIONS]: [
    ConversationStepsName.LOCALISATION_MARKET_SELECTION,
    ConversationStepsName.ASSET_ELEMENT_IDENTIFIED,
  ],
  [StepperStepsName.LOCALISATION]: [
    ConversationStepsName.USER_PROCEED_TO_LOCALISATION,
    ConversationStepsName.LOCALISATION_WITHOUT_TRANSLATION,
  ],
  [StepperStepsName.TRANSLATION]: [
    ConversationStepsName.USER_PROCEED_TO_TRANSLATION,
    ConversationStepsName.LOCALISATION_WITH_TRANSLATION,
  ],
  [StepperStepsName.PERSONALISATION]: [
    ConversationStepsName.USER_PROCEED_TO_PERSONALISATION_WITHOUT_TRANSLATION,
    ConversationStepsName.PERSONALISATION_WITH_TRANSLATION_AND_LOCALISATION,
  ],
  // [StepperStepsName.OUTPUT_PROCESSING]: [
  //   ConversationStepsName.USER_GENERATE_ASSET,
  //   ConversationStepsName.PERSONALISATION_WITH_TRANSLATION_AND_LOCALISATION,
  // ],
  [StepperStepsName.MLR_SUBMISSION]: [
    ConversationStepsName.USER_PROCEED_TO_MLR_SUBMISSION,
    ConversationStepsName.MLR_ASSETS_APPROVED,
  ],
};
