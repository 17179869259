import React from "react";
import { Collapse } from "antd";
import { Children } from "./Children";
import { CustomPanelHeader } from "./CustomHeader";

interface Data {
  id: string;
  name: string;
  key_message: string;
  reference: string[];
  footnote: string[];
  language: string;
}
export function SuggestedContent({ data }: { data: Data }) {
  const items = [
    {
      key: "1",
      label: (
        <CustomPanelHeader
          data={data}
          id={data["id"]}
          tag={data["key_message"]}
          title={data["name"]}
        />
      ),
      children: <Children references={data["reference"]} footnotes={data["footnote"]} />,
    },
  ];
  return (
    <div
      style={{
        border: "1px solid lightGray",
        borderRadius: "10px",
      }}
    >
      <Collapse
        items={items}
        expandIconPosition="end"
        bordered={false}
        style={{ backgroundColor: "white" }}
      />
    </div>
  );
}
