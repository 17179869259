import React, { useEffect, useRef } from "react";
import { Card, Checkbox, Row, Col, Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import PersonaInfo from "./PersonaInfo";
import LocalizationModal from "./LocalizationModal";

// import { axiosClient } from "../Services/Axios"
import { getPersonas } from "../../services/contentReplicatorServices";
import { useAppDispatch } from "../../store/hooks";
import { setCurrentStepLoading } from "../../store/slices/contentReplicatorSlice";
const { Paragraph } = Typography;
interface CardsProps {
  text?: string;
  modalPrice?: number;
}

const Cards: React.FC<CardsProps> = ({ text, modalPrice }) => {
  const [checkedValues, setCheckedValues] = React.useState<number[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState<Persona[]>([]);
  const [modal, setModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<number>(0);
  const [priceModal, setPriceModal] = React.useState<boolean>(false);

  const elementRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentStepLoading(true));
  }, []);

  useEffect(() => {
    if (elementRef.current) {
      setTimeout(() => {
        elementRef.current &&
          elementRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
    }
  }, [elementRef.current, isLoading]);

  useEffect(() => {
    if (checkedValues.length > 0) {
      dispatch(setCurrentStepLoading(false));
    } else {
      dispatch(setCurrentStepLoading(true));
    }
  }, [checkedValues]);

  const onChange = (checkedValues: number[]) => {
    setCheckedValues(checkedValues);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await getPersonas("persona");
        console.log(response);
        const fetchedData = response || [];
        // const fetchedData = response?.data?.data || []; // Default to empty array if data is null or undefined
        console.log("testop2", fetchedData);
        setData(fetchedData as Persona[]);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleSelectDeselectAll = () => {
    if (checkedValues.length === data.length) {
      setCheckedValues([]);
    } else {
      setCheckedValues(data.map(option => option.id));
    }
  };

  console.log({ checkedValues });

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <>
      <div style={{ marginBottom: "10px" }}>
        <label style={{ cursor: "pointer" }}>
          <Checkbox
            checked={checkedValues.length === data.length}
            indeterminate={checkedValues.length > 0 && checkedValues.length < data.length}
            onChange={handleSelectDeselectAll}
          />{" "}
          <b>Personalisation:</b> Select personas for whom you want to personalise the assets.
        </label>
      </div>
      <Checkbox.Group value={checkedValues} onChange={onChange} ref={elementRef}>
        <Row gutter={[16, 16]}>
          {data.length > 0 ? (
            data.map((option, index) => (
              <Col span={6} key={index}>
                <Card
                  style={{
                    height: "100%",
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    if (checkedValues.includes(option.id)) {
                      setCheckedValues(prevCheckedValues =>
                        prevCheckedValues.filter(value => value !== option.id),
                      );
                    } else {
                      setCheckedValues(prevCheckedValues => [...prevCheckedValues, option.id]);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Checkbox value={option.id} style={{ marginRight: 8 }} />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontWeight: "bold" }}>{option.name}</span>
                      <span>{option?.subtitle}</span>
                    </div>
                    <Tooltip title="More information">
                      <InfoCircleOutlined
                        onClick={e => {
                          e.stopPropagation();
                          setModal(true);
                          setSelectedId(option.id);
                        }}
                      />
                    </Tooltip>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <p>No data available</p>
          )}
        </Row>
      </Checkbox.Group>
      {/* {text && <Paragraph style={{ color: "#A3A3A5", marginTop: "1rem" }}>{text}</Paragraph>}

      {modalPrice && (
        <div style={{ display: "flex", columnGap: "1rem", alignItems: "baseline" }}>
          <Paragraph style={{ color: "#438F6A" }}>${modalPrice}</Paragraph>
          <div>
            <InfoCircleOutlined
              onClick={() => {
                setPriceModal(true);
              }}
            />
          </div>
        </div>
      )} */}
      {priceModal && (
        <>
          <LocalizationModal priceModal={priceModal} setPriceModal={setPriceModal} />
        </>
      )}
      {modal && (
        <PersonaInfo
          isDrawerVisible={modal}
          setisDrawerVisible={setModal}
          data={data}
          SelectedId={selectedId}
        />
      )}
    </>
  );
};

export default Cards;

// import React, { useEffect } from 'react';
// import { Card, Checkbox, Row, Col, Tooltip } from 'antd';
// import { InfoCircleOutlined } from '@ant-design/icons';
// import { axiosClient } from "../Services/Axios"

// const options = [
//   {
//     label: 'The Evidence-Based Physician',
//     description: 'Integrate research into practice, valuing scientific rigor and leading their field.',
//   },
//   {
//     label: 'Tech-Savvy Specialists',
//     description: 'They embrace new technologies, pioneering medical innovations.',
//   },
//   {
//     label: 'Community Focused Practitioners',
//     description: 'They prioritize local health, emphasizing preventive care and education.',
//   },
//   {
//     label: 'The Holistic Healer',
//     description: 'They integrate traditional and alternative therapies, addressing mind, body, and spirit.',
//   },
//   {
//     label: 'The Research-Oriented Academic',
//     description: 'They teach, research, and contribute to medical advancement.',
//   },
// ];

// const YourComponent: React.FC = () => {
//   const [checkedValues, setCheckedValues] = React.useState<string[]>([]);

//   const onChange = (checkedValues: string[]) => {
//     setCheckedValues(checkedValues);
//   };

//   const [isLoading, setIsLoading] = React.useState(true);

//   const [data, setData] = React.useState(null);

//   useEffect(() => {

//     ; (async () => {
//       try {
//         setIsLoading(true)

//         const response = await axiosClient.get('/persona');
//         const data = response.data.data
//         console.log(data)
//         setData(data);
//       } catch (err) {
//         console.error('Error fetching data:', err);
//       } finally {
//         setIsLoading(false)
//       }
//     })();
//   }, []);
//   return (

//     isLoading ? <div>Loading...</div> : (

//       <Checkbox.Group value={checkedValues} onChange={onChange}>
//         <Row gutter={[16, 16]}>
//           {data.map((option, index) => (
//             <Col span={6} key={index}>
//               <Card style={{ height: "120px" }}>
//                 <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'baseline' }}>
//                   <Checkbox value={option.label} style={{ marginRight: 8 }} />
//                   <div style={{ display: 'flex', flexDirection: 'column' }}>
//                     <span style={{ fontWeight: 'bold' }}>{option.label}</span>
//                     <span>{option?.description}</span>
//                   </div>
//                   <Tooltip title="More information">
//                     <InfoCircleOutlined />
//                   </Tooltip>

//                 </div>
//               </Card>
//             </Col>
//           ))}
//         </Row>
//       </Checkbox.Group>
//     )

//   );
// };

// export default YourComponent;
