export const localisationItemsList = [
  {
    asset_id: "1adFwef",
    market: "Spain",
    language: "Spanish",
    persona: "Early Adopter",
    type: "AI Generated",
    thumbnail:
      "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/rte_us_en_thumbnail.png",
    url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf",
  },
  {
    asset_id: "1adFwef1",
    market: "Spain",
    language: "Spanish",
    persona: "Evidence Focused",
    type: "AI Generated",
    thumbnail:
      "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/rte_us_en_thumbnail.png",
    url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf",
  },
  {
    asset_id: "1adFwef2",
    market: "Spain",
    language: "Spanish",
    persona: "Patient Centric",
    type: "AI Generated",
    thumbnail:
      "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/rte_us_en_thumbnail.png",
    url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf",
  },
  {
    asset_id: "1adFwef3",
    market: "Spain",
    language: "Spanish",
    persona: "Experience Focused",
    type: "AI Generated",
    thumbnail:
      "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/rte_us_en_thumbnail.png",
    url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf",
  },
];

export const translationItemsList = localisationItemsList.map((item, index) => ({
  ...item,
  asset_id: `1adFwef4${index}`,
}));

export const personalisationTrlnItemList = localisationItemsList.map((item, index) => ({
  ...item,
  asset_id: `1adFwef5${index}`,
  type: "Personalisation with translation",
}));

export const personalisationNoTrlnItemList = localisationItemsList.map((item, index) => ({
  ...item,
  asset_id: `1adFwef6${index}`,
  type: "Personalisation without translation",
}));

export const mockData = {
  localisation: localisationItemsList,
  localisationWithTranslation: translationItemsList,
  personalisation: personalisationNoTrlnItemList,
  personalisationWithTranslation: personalisationTrlnItemList,
};
