// @ts-ignore
// import pdf from "../assets/image_graphics.pdf";
import StubixynPDF from "../assets/Annotative RTE Demo Asset - US English.pdf";
// @ts-ignore
import PainCurePDF from "../assets/PainCure Annotative - US English.pdf";
// @ts-ignore
import PrevivaAnnotatedPDF from "../assets/Annotative Previva RTE Demo Asset.pdf";

export const annotatedPDFURLs = {
  PainCure: PainCurePDF,
  Stubixyn: StubixynPDF,
  PREVIVA: PrevivaAnnotatedPDF,
};
