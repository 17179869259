import React, { useState } from "react";
import { Document, DocumentProps, Page, pdfjs } from "react-pdf";
import "./PDFViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type PDFViewerProps = {
  file?: DocumentProps["file"];
  url?: string;
  scale?: number;
  pageNumber?: number;
  showAnnotations?: boolean;
  showTextLayer?: boolean;
  className?: string;
};
export const PDFViewerComponent: React.FC<PDFViewerProps> = ({
  file,
  url,
  scale = 2,
  showAnnotations = false,
  showTextLayer = false,
  className,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    console.log({ numPages });
    setNumPages(numPages);
  }
  return (
    <Document
      className={`react-pdf-viewer ${className ? className : ""}`}
      file={file || { url }}
      noData="No PDF file specified"
      onLoadError={error => console.log("Error loading PDF:", error)}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={scale}
            renderAnnotationLayer={showAnnotations}
            renderTextLayer={showTextLayer}
          />
        ))}
      </div>
    </Document>
  );
};

export const PDFViewer = React.memo(PDFViewerComponent);
