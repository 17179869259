class CancellationManager {
  private controllers: Map<string, AbortController> = new Map();

  // Create a new AbortController and add it to the map
  createAbortController(key: string): AbortController {
    const controller = new AbortController();
    this.controllers.set(key, controller);
    return controller;
  }

  getAbortController(key: string): AbortController | undefined {
    return this.controllers.get(key);
  }

  abortRequest(key: string, message: string = "Request aborted by the manager."): void {
    const controller = this.controllers.get(key);
    if (controller) {
      controller.abort(message);
      this.controllers.delete(key);
    }
  }

  abortAllRequests(message: string = "All requests aborted by the manager."): void {
    this.controllers.forEach(controller => {
      controller.abort();
    });
    this.controllers.clear();
  }
}

const cancellationManager = new CancellationManager();
export default cancellationManager;

/**
 * Usage in Component
 */
// import React, { useEffect, useState } from 'react';
// import { getUser } from './services/userService';
// import cancellationManager from './services/cancellationManager';

// const UserComponent: React.FC = () => {
//   const [user, setUser] = useState<User | null>(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const requestKey = 'fetchUser_1'; // Unique key for this request

//     const fetchUser = async () => {
//       try {
//         const userData = await getUser(1, requestKey);
//         setUser(userData);
//       } catch (error) {
//         if (error.name === 'AbortError') {
//           console.log('Request aborted');
//         } else {
//           setError('Failed to fetch user');
//         }
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUser();

//     // Cleanup function to cancel the request if the component unmounts
//     return () => {
//       cancellationManager.abortRequest(requestKey);
//     };
//   }, []);

//   // Example of how to abort all requests
//   const abortAll = () => {
//     cancellationManager.abortAllRequests('User initiated abort.');
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>{error}</div>;
//   return (
//     <div>
//       {user ? `User: ${user.name}` : 'No user found'}
//       <button onClick={abortAll}>Abort All Requests</button>
//     </div>
//   );
// };

// export default UserComponent;
