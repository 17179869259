import React from "react";
import { Card, Row, Col } from "antd";

import LocationIcon from "../assets/svg/location.svg";
import language_chinese from "../assets/svg/language_chinese_dayi.svg";
import account_circle from "../assets/svg/account_circle.svg";

interface CardData {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
}

const ContentCard: React.FC<CardData> = ({ icon, title, subtitle }) => {
  const cardStyle: React.CSSProperties = {
    width: "100%",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    textAlign: "left",
    padding: "12px",
  };

  const contentStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    rowGap: "9px", // Use rowGap to add vertical space between items
  };

  const iconContainerStyle: React.CSSProperties = {
    backgroundColor: "#F3E8FF",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 16px 0 0", // Adjust margin to align left
  };

  const iconStyle: React.CSSProperties = {
    fontSize: "24px",
    color: "#000000",
  };

  const titleStyle: React.CSSProperties = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "4px",
  };

  const subtitleStyle: React.CSSProperties = {
    fontSize: "14px",
    color: "#6B7280",
  };

  return (
    <Card style={cardStyle}>
      <div style={contentStyle}>
        <div style={iconContainerStyle}>{icon}</div>
        <div style={titleStyle}>{title}</div>
        <div style={subtitleStyle}>{subtitle}</div>
      </div>
    </Card>
  );
};

const ContentManagementCards: React.FC = () => {
  const cardsData: CardData[] = [
    {
      icon: <img src={LocationIcon} alt="Localization Icon" />,
      title: "Content Creation",
      subtitle: "Specify market for asset content creation",
    },
    {
      icon: <img src={language_chinese} alt="Localization Icon" />,
      title: "Translation",
      subtitle: "Specify Language for asset translation",
    },
    {
      icon: <img src={account_circle} alt="Localization Icon" />,
      title: "Personalization",
      subtitle: "Specify personas for asset personalisation",
    },
  ];

  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "40px" }}
    >
      <Row gutter={[16, 16]} justify="center">
        {cardsData.map((card, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
            <ContentCard {...card} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ContentManagementCards;
