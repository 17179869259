import React, { useState } from "react";
import { Row, Col, Typography, Skeleton } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import Parsona from "../assets/Persona.jpg";
const { Text } = Typography;
const ImageGallery = ({
  isTyped,
  setOpenEditModal,
  Isloder,
  selectedImage,
  setSelectedImage,
  setIsTyped,
  byDefaultSelectedImage,
}: {
  isTyped: boolean;
  setOpenEditModal: Function;
  Isloder: boolean;
  setSelectedImage: Function;
  selectedImage: string;
  setIsTyped: Function;
  byDefaultSelectedImage: string;
}) => {
  const thumbnails = [
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/localization_without_Trans.jpg?img=CP-009225A",
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/AI_2.jpg?img=CP-008625A ",
    "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/3.jpg?img=CP-000725A ",
  ];

  return (
    <Col
      span={8}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{ position: "relative", display: "flex", flexDirection: "column", height: "100%" }}
      >
        {/* TODO:back button */}
        <h3
          style={{ fontWeight: "600", cursor: "pointer" }}
          onClick={() => {
            setOpenEditModal(false);
            setIsTyped(false);
          }}
        >
          {"<"} Image Regeneration
        </h3>
        <div style={{ position: "relative", padding: "16px", display: "flex", height: "100%" }}>
          <img
            src={selectedImage ? selectedImage.split("?")[0] : byDefaultSelectedImage}
            alt="Main Image"
            style={{
              width: "100%",
              // height: "500px",
              objectFit: "contain",
              borderRadius: 8,
              marginBottom: 8,
            }}
          />
          {
            <Text
              style={{
                position: "absolute",
                bottom: isTyped ? "95px" : "130px",
                right: isTyped ? "18px" : "18px",
                backgroundColor: "rgba(0,0,0,0.5)",
                color: "white",
                padding: "2px 4px",
                borderRadius: 4,
                fontSize: 12,
              }}
            >
              PromoMats ID:
              <span style={{ color: "white" }}>
                {selectedImage ? selectedImage.split("=")[1] : "CP-002025"}
              </span>
            </Text>
          }
        </div>
        {/* 
        {!selectedImage && (


          <div style={{
            position: "absolute",
            bottom: 90,
            left: 18,


          }}>
            <Text>
              PromoMats ID:<span style={{ color: "#5B4394" }}>{"CP-002025"}</span>
            </Text>
          </div>
        )
        } */}
      </div>
      {isTyped && (
        <div
          style={{
            overflowX: "auto",
            paddingBottom: 8,
            overflowY: "hidden",
          }}
        >
          {Isloder && <span>Generating...</span>}
          <Skeleton loading={Isloder} paragraph={{ rows: 1 }}>
            <Row gutter={8} style={{ display: "flex", flexWrap: "nowrap" }}>
              {thumbnails.map((thumbnail, index) => (
                <Col key={index} span={8} style={{ flex: "0 0 auto", position: "relative" }}>
                  <img
                    src={thumbnail}
                    alt={`Thumbnail ${index + 1}`}
                    style={{ width: "100%", borderRadius: 4, cursor: "pointer" }}
                    onClick={() => setSelectedImage(thumbnail)}
                  />
                  {selectedImage === thumbnail && (
                    <CheckCircleFilled
                      style={{
                        position: "absolute",
                        bottom: 4,
                        right: 4,
                        color: "#6752e9",
                        fontSize: 16,
                      }}
                    />
                  )}
                </Col>
              ))}
            </Row>
          </Skeleton>
        </div>
      )}
    </Col>
  );
};

export default ImageGallery;
