import { configureStore } from "@reduxjs/toolkit";
import contentReplicatorSlice from "./slices/contentReplicatorSlice";

// Root store configuration
export const store = configureStore({
  reducer: {
    contentReplicator: contentReplicatorSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
