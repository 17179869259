import React from "react";
import { Button } from "antd";

interface GetStartedButtonProps {
  onClick?: () => void;
}

const GetStartedButton: React.FC<GetStartedButtonProps> = ({ onClick }) => {
  const buttonStyle: React.CSSProperties = {
    backgroundColor: "#6C3CE2",
    borderColor: "#6C3CE2",
    borderRadius: "12px", // Adjusted border radius
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "bold",
    // padding: '10px 24px', // Adjusted padding
    padding: "20px",
    // transition: 'background-color 0.3s ease',
  };

  const hoverStyle: React.CSSProperties = {
    backgroundColor: "#5A32BF",
    borderColor: "#5A32BF",
  };

  return (
    <Button
      type="primary"
      style={buttonStyle}
      onMouseEnter={e => {
        const target = e.target as HTMLElement;
        Object.assign(target.style, hoverStyle);
      }}
      // onMouseLeave={e => {
      //   const target = e.target as HTMLElement;
      //   Object.assign(target.style, buttonStyle);
      // }}
      onClick={onClick}
    >
      Get Started
    </Button>
  );
};

export default GetStartedButton;
