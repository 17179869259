import React, { useState, useEffect } from "react";
import { Spin, Typography, Button } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useAppDispatch } from "../../../../../store/hooks";
import { setCurrentStepLoading } from "../../../../../store/slices/contentReplicatorSlice";

const { Text } = Typography;

interface Step {
  id?: number;
  label: string;
  loading: boolean;
  completed: boolean;
  viewable: boolean;
  onClick?: () => void;
}

type StepProps = {
  id?: number;
  label: string;
  onClick?: () => void;
};

type StepWiseProgressProps = {
  steps: StepProps[];
  viewButtons?: boolean;
  viewAllButton?: boolean;
  onViewAllClick?: () => void;
  setOpenedModalId?: Function;
};
const getSteps = (steps: StepProps[], viewButtons: boolean = false) => {
  return steps.map((step, index) => ({
    ...step,
    loading: index === 0 ? true : false,
    completed: false,
    viewable: viewButtons && index !== 0,
    onClick: step.onClick,
  }));
};

const testData: Step[] = [
  { label: "Localisation without translation", loading: true, completed: false, viewable: false },
  { label: "Equivalent Claims Identified", loading: false, completed: false, viewable: true },
  {
    label: "Localised reusable text (Non-claims) generated",
    loading: false,
    completed: false,
    viewable: true,
  },
  { label: "Localising Image/Graphic component", loading: false, completed: false, viewable: true },
  {
    label: "Assemble transformed elements for personalise (without translation)",
    loading: false,
    completed: false,
    viewable: true,
  },
];

export const StepWiseProgress: React.FC<StepWiseProgressProps> = ({
  steps,
  viewButtons = false,
  setOpenedModalId,
  viewAllButton,
  onViewAllClick,
}) => {
  const [stepsData, setStepsData] = useState<Step[]>(getSteps(steps, viewButtons));
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentStepLoading(true));
  }, []);

  useEffect(() => {
    if (stepsData[stepsData.length - 1].completed) dispatch(setCurrentStepLoading(false));
  }, [stepsData]);

  useEffect(() => {
    if (currentStepIndex < stepsData.length) {
      const timer = setTimeout(
        () => {
          // Mark the current step as completed
          setStepsData(prevSteps => {
            const updatedSteps = [...prevSteps];
            updatedSteps[currentStepIndex].loading = false;
            updatedSteps[currentStepIndex].completed = true;
            return updatedSteps;
          });

          // Move to the next step
          setCurrentStepIndex(prevIndex => prevIndex + 1);

          // Mark the next step as loading (if any)
          if (currentStepIndex + 1 < stepsData.length) {
            setStepsData(prevSteps => {
              const updatedSteps = [...prevSteps];
              updatedSteps[currentStepIndex + 1].loading = true;
              return updatedSteps;
            });
          }
        },
        2000, // 2s
        // Math.random() * 700 + 500,
      ); // a random delay between 500ms and 1200ms

      return () => clearTimeout(timer);
    }
  }, [currentStepIndex]);

  return (
    <div style={styles.container}>
      {stepsData.map((step, index) => (
        <div key={index} style={index === 0 ? styles.mainStep : styles.subStep}>
          <div style={styles.icon}>
            {step.completed ? (
              <CheckCircleFilled style={{ color: "green", fontSize: "16px" }} />
            ) : step.loading ? (
              <Spin size="small" />
            ) : (
              <span style={{ fontSize: "20px" }}></span>
            )}
          </div>
          <Text
            strong={step.loading}
            style={{ fontWeight: step.loading ? "600" : "normal", fontSize: "12px" }}
          >
            {step.label}
          </Text>
          {step.viewable && step.completed && (
            <Button
              type="link"
              style={styles.viewButton}
              onClick={() => setOpenedModalId?.(step.id)}
            >
              View
            </Button>
          )}
        </div>
      ))}
      {viewAllButton && stepsData[stepsData.length - 1].completed && (
        <Button type="link" style={styles.viewButton} onClick={onViewAllClick}>
          View All
        </Button>
      )}
    </div>
  );
};

const styles = {
  container: {
    // padding: "20px",
    maxWidth: "600px",
    fontSize: "10px",
  },
  mainStep: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  subStep: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    paddingLeft: "30px",
    height: "16px",
  },
  icon: {
    marginRight: "10px",
  },
  viewButton: {
    marginLeft: "10px",
    color: "#5d2cc9",
    fontWeight: "600",
    fontSize: "12px",
  },
};
