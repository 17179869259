import React, { useEffect, useState } from "react";
import { Layout, Menu, MenuProps } from "antd";
import {
  DashboardOutlined,
  MessageOutlined,
  AppstoreOutlined,
  SettingOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { ASSETS } from "../../assets/assetsSrcMap";
import "./Sidebar.css";
import ROUTE_PATHS from "../../routes/routePaths";
import { useLocation, useNavigate } from "react-router-dom";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    key: ROUTE_PATHS.CONTENT_REPLICATOR,
    label: "Content Replicator",
    icon: <img src={ASSETS.SIDEBAR_CONTENT_REPLICATOR_ICON} height={18} width={16} />,
  },
  {
    key: "reports",
    label: "Reports",
    icon: <MessageOutlined style={{ fontSize: "16px" }} />,
    disabled: true,
  },
  {
    key: "rearrange-assets",
    label: "Rearrange Assets",
    icon: <AppstoreOutlined style={{ fontSize: "16px" }} />,
    disabled: true,
  },
  {
    key: "settings",
    label: "Settings",
    icon: <SettingOutlined style={{ fontSize: "16px" }} />,
    disabled: true,
  },
];

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.CONTENT_REPLICATOR) {
      setSelectedKey(ROUTE_PATHS.CONTENT_REPLICATOR);
    } else {
      setSelectedKey(null);
    }
  }, [location.pathname]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onMenuClick: MenuProps["onClick"] = ({ key }) => {
    //navigate(key);
    navigate(ROUTE_PATHS.CONTENT_REPLICATOR);
  };

  return (
    <Sider
      className="app-sidebar"
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
      trigger={null}
    >
      <Menu
        onClick={onMenuClick}
        // defaultSelectedKeys={[ROUTE_PATHS.CONTENT_REPLICATOR]}
        selectedKeys={selectedKey ? [selectedKey] : []}
        mode="inline"
        items={items}
      />
      <div
        onClick={() => setCollapsed(!collapsed)}
        style={{
          height: "20px",
          width: "20px",
          borderRadius: "50%",
          position: "absolute",
          left: "100%",
          transform: "translateX(-50%)",
          top: "50%",
          cursor: "pointer",
          zIndex: 1,
          color: "black",
          background: "white",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {collapsed ? <RightOutlined /> : <LeftOutlined />}
      </div>
    </Sider>
  );
};

export default Sidebar;
