import StubixynOrignalImage from "../assets/Original.webp";
import PainCureOrignalImage from "../assets/paincure/Picture of a Person 1.jpg";
import PrevivaOriginalImage from "../assets/previva/source - input.jpg";
import Parsona4 from "../assets/paincure/persona 1.jpg";
import Parsona3 from "../assets/paincure/persona 2.jpg";
import Parsona1 from "../assets/paincure/persona 3.jpg";
import Parsona2 from "../assets/paincure/persona 4.jpg";
import PrevivaPersona1 from "../assets/previva/p1 early adopter.jpg";
import PrevivaPersona2 from "../assets/previva/p2 Evidence Focused.jpg";
import PrevivaPersona3 from "../assets/previva/p3 Patient Centric.jpg";
import PrevivaPersona4 from "../assets/previva/p4 Experience Focused.jpg";

interface Iimages {
  id: number;
  src: string;
  alt: string;
  tag: string;
  tagColor: string;
  matchRate?: number;
  label?: string;
  promoMatsId: string;
  parsona: string;
}

interface BrandImageObj {
  url: string;
  persona: string;
  promoMatsId: string;
}

const brandImageLists: Record<string, BrandImageObj[]> = {
  Stubixyn: [
    {
      url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona1_alt.jpg",
      persona: "Early Adopter",
      promoMatsId: "CP-002025",
    },
    {
      url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona3.jpg",
      persona: "Evidence Focused",
      promoMatsId: "CP-003225",
    },
    {
      url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona2.jpg",
      persona: "Patient Centric",
      promoMatsId: "CP-002065",
    },
    {
      url: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona1_alt.jpg",
      persona: "Experience Focused",
      promoMatsId: "CP-002080",
    },
  ],
  PainCure: [
    {
      url: Parsona1,
      persona: "Early Adopter",
      promoMatsId: "CP-002025",
    },
    {
      url: Parsona2,
      persona: "Evidence Focused",
      promoMatsId: "CP-003225",
    },
    {
      url: Parsona3,
      persona: "Patient Centric",
      promoMatsId: "CP-002065",
    },
    {
      url: Parsona4,
      persona: "Experience Focused",
      promoMatsId: "CP-002080",
    },
  ], // Maintain the Order
  PREVIVA: [
    {
      url: PrevivaPersona1,
      persona: "Early Adopter",
      promoMatsId: "CP-002025",
    },
    {
      url: PrevivaPersona2,
      persona: "Evidence Focused",
      promoMatsId: "CP-003225",
    },
    {
      url: PrevivaPersona3,
      persona: "Patient Centric",
      promoMatsId: "CP-002065",
    },
    {
      url: PrevivaPersona4,
      persona: "Experience Focused",
      promoMatsId: "CP-002080",
    },
  ], // Maintain the Order
};

//create a function to generate the images object based on imagesURLs links
const generateImages = (imageObjs: BrandImageObj[]) => {
  const images: Iimages[] = [];
  imageObjs.forEach((imageObj, index) => {
    images.push({
      id: index + 1,
      src: imageObj.url,
      alt: "Man in suit with shoulder pain",
      tag: "AI Generated",
      tagColor: "purple",
      promoMatsId: imageObj.promoMatsId,
      parsona: imageObj.persona,
    });
  });
  return images;
};

const imagesPainCure: Iimages[] = [
  {
    id: 1,
    src: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona1_alt.jpg",
    alt: "Man in suit with shoulder pain",
    tag: "AI Generated",
    tagColor: "purple",
    promoMatsId: "CP-002025",
    parsona: "Early Adopter",
  },
  {
    id: 2,
    src: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona3.jpg",
    alt: "Bearded man with shoulder pain",
    tag: "AI Generated",
    tagColor: "purple",
    matchRate: 90,
    // label: "Tech-Savvy Specialist",
    promoMatsId: "CP-003225",
    parsona: "Evidence Focused",
  },
  {
    id: 3,
    src: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona2.jpg",
    alt: "Shirtless man with shoulder pain",
    tag: "AI Generated",
    tagColor: "purple",
    matchRate: 65,
    promoMatsId: "CP-002065",
    parsona: "Patient Centric",
  },
  {
    id: 4,
    src: "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/persona1_alt.jpg",
    alt: "Person touching their shoulder",
    tag: "VVPM",
    tagColor: "orange",
    // label: "Community-Focus",
    promoMatsId: "CP-002080",
    parsona: "Experience Focused",
  },
];

const vvpmImages = [
  "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/localization_without_Trans.jpg?img=CP-009225A",
  "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/AI_2.jpg?img=CP-008625A",
  "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/3.jpg?img=CP-000725A",
];
const aiGeneratedImages = [
  "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/AI_1.jpg?img=CP-009225A",
  "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/1.jpg?img=CP-008625A",
  "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/2.jpg?img=CP-000725A",
];
let data = {
  PainCure: {
    originialImage: PainCureOrignalImage,
    images: generateImages(brandImageLists["PainCure"]),
    vvpmImages,
    aiGeneratedImages,
  },
  Stubixyn: {
    originialImage: StubixynOrignalImage,
    images: generateImages(brandImageLists["Stubixyn"]),
    vvpmImages,
    aiGeneratedImages,
  },
  PREVIVA: {
    originialImage: PrevivaOriginalImage,
    images: generateImages(brandImageLists["PREVIVA"]),
    vvpmImages,
    aiGeneratedImages,
  },
};
export default data;
