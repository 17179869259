import React from "react";
import { Dropdown, MenuProps } from "antd";
import { LogoutOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { ASSETS } from "../../assets/assetsSrcMap";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "../../routes/routePaths";

const AppHeader: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      label: (
        <div style={{ display: "flex", gap: "8px" }} onClick={logout}>
          <LogoutOutlined />
          Logout
        </div>
      ),
      key: "0",
    },
  ];

  const handleLogoClick = () => {
    navigate(ROUTE_PATHS.DASHBOARD);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "18px 35px",
        background: "#fafafa",
        borderBottom: "1px solid #d1d5db",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", gap: "2px", cursor: "pointer" }}
        onClick={handleLogoClick}
      >
        <img src={ASSETS.APP_LOGO} alt="Header Logo" />
        <div style={{ fontSize: "12px", textAlign: "left", fontWeight: 700 }}>
          <div>Super</div>
          <div>Agent</div>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <img
          src={ASSETS.HEADER_NOTIFICATION}
          alt="Notification"
          style={{ height: "24px", cursor: "pointer" }}
        />
        <div style={{ color: "#767676", display: "flex", gap: "5px", cursor: "pointer" }}>
          <span style={{ fontSize: "14px" }}>Help</span>
          <QuestionCircleOutlined />
        </div>
        <Dropdown menu={{ items }}>
          <img
            src={ASSETS.HEADER_AVATAR}
            alt="Avatar"
            style={{ height: "24px", cursor: "pointer" }}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default AppHeader;
