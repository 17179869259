"use client";

import React, { useState } from "react";
import { Drawer, Button, Collapse, Avatar, Row, Col, Typography, Card, ConfigProvider } from "antd";
import { CaretRightOutlined, UserOutlined } from "@ant-design/icons";
import "./PersonaInfo.css";

const { Title, Text, Paragraph } = Typography;

interface PersonaInfo {
  title: string;
  description: string;
  profileSummary: string;
  demographics: {
    age: string;
    gender: string;
    specialty: string;
  };
  goals: string[];
  challenges: string[];
  strategicActions: string[];
  brandDirection: {
    valueProposition: string;
    brandMessage: string;
  };
}

const personaInfo: PersonaInfo = {
  title: "The Evidence-Based Physician",
  description:
    "Integrate research into practice, valuing scientific rigour and leading their field.",
  profileSummary:
    "The Evidence-Based Physician is dedicated to integrating the latest research findings into their clinical practice. They value scientific rigour and are often seen as thought leaders in their field.",
  demographics: {
    age: "40-60",
    gender: "Both Male & Female",
    specialty: "Often found in specialties like cardiology, oncology, or internal medicine",
  },
  goals: [
    "Stay updated with the latest medical research and clinical guidelines.",
    "Provide the highest standard of care based on evidence.",
  ],
  challenges: [
    "Keeping up with the vast amount of new research.",
    "Integrating new evidence into practice amidst time.",
  ],
  strategicActions: [
    "Content Strategy: Publish research summaries, clinical trial results, and guideline updates.",
    "Engagement Strategy: Organize webinars and continuing medical education (CME) events featuring key opinion leaders.",
  ],
  brandDirection: {
    valueProposition: "Deliver cutting-edge research and practical tools for implementation.",
    brandMessage: "Empowering you with the latest evidence.",
  },
};

const otherPersonas = [
  {
    key: "evidence",
    title: "Evidence Focused",
    description: "Data-driven decision makers prioritise evidence-based medicine.",
  },
  {
    key: "patient",
    title: "Patient Centric",
    description: "Priorities the needs, preferences, & experiences of patients in medical care.",
  },
  {
    key: "experience",
    title: "Experience Focused",
    description: "Emphasises the overall experience of patients within the medical system.",
  },
];

const InfoBox: React.FC<{ title: string; items?: string[]; content?: React.ReactNode }> = ({
  title,
  items,
  content,
}) => (
  <Card bordered={false} style={{ background: "#F5F5F5", padding: "16px", marginBottom: "16px" }}>
    <Title level={5}>{title}</Title>
    {items ? (
      <ul style={{ paddingLeft: "20px", margin: 0 }}>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    ) : (
      content
    )}
  </Card>
);

const PersonaDetails: React.FC = () => {
  const cardStyle: React.CSSProperties = {
    backgroundColor: "#f0f2f5",
    borderRadius: "8px",
    height: "100%",
  };

  const titleStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "16px",
  };

  const listStyle: React.CSSProperties = {
    paddingLeft: "20px",
    marginBottom: "0",
  };

  const listItemStyle: React.CSSProperties = {
    marginBottom: "8px",
  };

  return (
    <Row gutter={[16, 16]}>
      <Col>
        <Card style={cardStyle}>
          <Title level={4} style={titleStyle}>
            Goals
          </Title>
          <ul style={listStyle}>
            {personaInfo.goals.map((goal, index) => (
              <li key={index} style={listItemStyle}>
                <Text>{goal}</Text>
              </li>
            ))}
          </ul>
          <Title level={4} style={{ ...titleStyle, marginTop: "24px" }}>
            Strategic Actions
          </Title>
          <ul style={listStyle}>
            {personaInfo.strategicActions.map((action, index) => (
              <li key={index} style={listItemStyle}>
                <Text>{action}</Text>
              </li>
            ))}
          </ul>
        </Card>
      </Col>
    </Row>
  );
};

export default function Component({
  isDrawerVisible,
  setisDrawerVisible,
  SelectedId,
  data,
}: {
  isDrawerVisible: boolean;
  setisDrawerVisible: (value: boolean) => void;
  SelectedId: number;
  data: Persona[];
}) {
  const cardStyle: React.CSSProperties = {
    backgroundColor: "#f0f2f5",
    borderRadius: "8px",
    height: "100%",
  };

  const titleStyle: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "16px",
  };

  const listStyle: React.CSSProperties = {
    paddingLeft: "20px",
    marginBottom: "0",
  };

  const listItemStyle: React.CSSProperties = {
    marginBottom: "8px",
  };
  // const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [activeKey, setActiveKey] = useState([SelectedId?.toString()]);

  // const showDrawer = () => setIsDrawerVisible(true)
  const handleCancel = () => setisDrawerVisible(false);

  const items = data?.map((item, index) => ({
    key: (index + 1).toString(), // key is index + 1 as a string
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <strong>{item?.name || "Unknown Name"}</strong>
          <div style={{ fontSize: "0.9em", color: "#888" }}>
            {item?.subtitle || "No profile summary available"}
          </div>
        </div>
        <CaretRightOutlined
          rotate={activeKey.includes((index + 1).toString()) ? 90 : 0}
          style={{ fontSize: "16px", transition: "0.3s" }}
        />
      </div>
    ),
    children: (
      <div style={{ background: "#E7EBEF", padding: "16px" }}>
        <Row
          gutter={[16, 16]}
          style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}
        >
          <Col span={2} style={{ alignSelf: "flex-start", padding: 0 }}>
            <Avatar
              src={item.image}
              shape="circle"
              style={{ padding: "5px", background: "white", width: "100%", height: "100%" }}
            />
            {/* <UserOutlined className="persona-avatar" /> */}
          </Col>
          <Col span={11}>
            <div>
              <Text strong>Profile Summary</Text>
              <Paragraph>{item?.profile_summary || "No profile summary available"}</Paragraph>
            </div>
            <Title level={5}>Demographic</Title>
            <Row>
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Text>Age:</Text>
                  </Col>
                  <Col span={24}>
                    <Text>{item?.demographics?.Age || "N/A"}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Text color="gray">Gender:</Text>
                  </Col>
                  <Col span={24}>
                    <Text>{item?.demographics?.Gender || "N/A"}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={24}>
                    <Text>Specialty:</Text>
                  </Col>
                  <Col span={24}>
                    <Text>{item?.demographics?.Specialties?.[0] || "N/A"}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
              <PersonaDetails />
            </Row>
          </Col>
          <Col span={11}>
            <Col>
              <Card style={cardStyle}>
                <Title level={4} style={titleStyle}>
                  Challenges
                </Title>
                <ul style={listStyle}>
                  {item?.goals_and_challenges?.Challenges?.map((challenge, index) => (
                    <li key={index} style={listItemStyle}>
                      <Text>{challenge || "No challenges available"}</Text>
                    </li>
                  ))}
                </ul>
                <Title level={4} style={{ ...titleStyle, marginTop: "24px" }}>
                  Brand Direction
                </Title>
                <ul style={listStyle}>
                  <li style={listItemStyle}>
                    <Text strong>Value Proposition:</Text>
                    <br />
                    <Text>
                      {item?.brand_direction?.["Value Proposition"] ||
                        "No value proposition available"}
                    </Text>
                  </li>
                  <li style={listItemStyle}>
                    <Text strong>Brand Message:</Text>
                    <br />
                    <Text>
                      {item?.brand_direction?.["Brand Message"] || "No brand message available"}
                    </Text>
                  </li>
                </ul>
              </Card>
            </Col>
          </Col>
        </Row>
      </div>
    ),
  }));

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", padding: "20px" }}>
      {/* <Button onClick={showDrawer}>Open Persona Info</Button> */}
      <Drawer
        title="Persona Information"
        open={isDrawerVisible}
        onClose={handleCancel}
        width={1200} // Increase the width here, adjust as needed
        closeIcon={null} // Hide the default close icon
        extra={<Button onClick={handleCancel}>Close</Button>} // Add a custom close button
        style={{ top: 20 }}
      >
        <ConfigProvider
          theme={{
            components: {
              Collapse: {
                contentBg: "#E7EBEF",
                headerBg: "#E7EBEF", // Default header background color
              },
            },
          }}
        >
          <Collapse
            activeKey={activeKey}
            onChange={keys => setActiveKey(keys as string[])}
            expandIcon={({ isActive }) => null} // Keep expand icon as null
            items={items?.map(item => ({
              ...item,
              key: item.key,
              style: {
                backgroundColor: activeKey.includes(item.key) ? "#E7EBEF" : "#fff", // Set white header when closed
              },
            }))}
            accordion
            bordered={false}
          />
        </ConfigProvider>
      </Drawer>
    </div>
  );
}
