import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

type RouteGuardProps = {
  element: React.ReactElement;
  isProtected?: boolean;
};

const RouteGuard: React.FC<RouteGuardProps> = ({ element, isProtected = false }) => {
  const { isAuthenticated } = useAuth();

  // Redirect to login if the route is protected and the user is not authenticated
  if (isProtected && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Redirect to dashboard if the route is public but the user is already authenticated
  if (!isProtected && isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return element;
};

export default RouteGuard;
